import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router";
import countries from "i18n-iso-countries";
import Breadcrumb from '../../breadcrumb';

import { Typeahead } from "react-bootstrap-typeahead";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { getDocTypes, identificationService, updateIdentificationStatusByUserId } from '../../../services/IdentificationService';
import { country } from '../../../helpers/country';
import { useSetState } from '../../helpers/customHooks';
import { migrantStatuses } from '../../../helpers/migrantStatuses'
import { getDocTypeLabel } from '../../../helpers/docTypeHelper'
import '../css/cardDoc.css';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { getDocumentsById, userService, getAddressesById } from '../../../services/userService'
import backgroundPlus from '../../../assets/images/other-images/plus.png'
import backgroundCross from '../../../assets/images/other-images/close.png'
import backgroundCheck from '../../../assets/images/other-images/check.png'
import AllertDialog from './AllertDialog'
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../helpers/roleHelper';
import { targetIdentification } from '../../../helpers/targetIdentification';

countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));

const Identification = () => {
    let documents = [];
    const isAgentFmr = jwt_decode(localStorage.getItem("token")).role === ROLES.AGENTFMR;
    const [isLoad, setIsLoad] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [checkDocuments, setCheckDocuments] = useState([]);
    const [checkAddress, setCheckAddress] = useState();
    const [isCompleteIdentificationButton, setIsCompleteIdentificationButton] = useState();

    let object = {
        "countryCode": "",
        "target": -1,
        "status": -1
    }

    const checkIsCompleteIdentification = () => {
        let flag = true;
        if (!documentTypes || documentTypes.length == 0)
            flag = false;
        documentTypes.map((item) => {
            if (item.value !== 100 && checkDocuments[item.value] !== 2)
                flag = false
        });
        setIsCompleteIdentificationButton(!flag);
    }

    const [isDisabledButton, setIsDisabledButton] = useState(true);
    const { id } = useParams();

    const [typeDocRequest, setTypeDocRequest] = useSetState({
        id: "",
        migrantStatus: -1,
        target: -1,
        countryCode: ""
    });

    useEffect(async () => {
        setIsDisabledButton(checkFields());
    }, [typeDocRequest])

    const checkFields = () => {
        return typeDocRequest.countryCode == '' || typeDocRequest.migrantStatus == null;
    };
    const updateIdentificationStatus = async (statusId) => {
        let request = {
            userId: id,
            status: statusId
        }
        await identificationService.updateIdentificationStatusByUserId(JSON.stringify(request))
            .catch(error => {
                toast.error(<p>{error.message}</p>);
            })
            .then(() => {
                toast.info(<p>Статус идентификации обновлен!</p>);
                if (isAgentFmr) {
                    window.location.href = `${process.env.PUBLIC_URL}/users`;

                }
                else {
                    window.location.href = `${process.env.PUBLIC_URL}/appIdentifications`;
                }
            });
    }
    async function getTypes() {
        if (typeDocRequest?.countryCode && typeDocRequest?.countryCode.length !== 0 && typeDocRequest?.migrantStatus !== undefined &&
            typeDocRequest?.migrantStatus !== -1 && typeDocRequest?.target !== undefined && typeDocRequest?.target !== -1) {
            await identificationService.getDocTypes(typeDocRequest.countryCode, typeDocRequest.migrantStatus, typeDocRequest.target)
                .catch(error => {
                    toast.error(<p>{error.message}</p>);
                }).then((data) => {
                    if (data) {
                        documents = [];
                        data.documents.map(doc => {
                            // Если трудовой договор или патент
                            if (doc === 6 || doc === 7 || doc === 10) {
                                return;
                            }
                            documents.push({ value: doc, label: getDocTypeLabel(doc) });
                        });
                        if (typeDocRequest?.migrantStatus !== 2) {
                            documents.push({ value: 100, label: getDocTypeLabel(100) });
                        }
                        setDocumentTypes(documents);
                        saveUserHistory(typeDocRequest.countryCode, typeDocRequest.migrantStatus, typeDocRequest.target);
                    }
                });
        }
    }

    const history = useHistory();

    function saveUserHistory(countryCode, status, target) {
        if (countryCode != "") {
            object.countryCode = countryCode
        }
        if (status != -1) {
            object.status = status
        }
        if (target != -1) {
            object.target = target
        }
        localStorage.setItem(id, JSON.stringify(object))
    }

    const clickOnCard = async (docTypeId) => {
        if (docTypeId === 100) {
            docTypeId = 5;
        }
        history.push(`/document/${id}/${docTypeId}`);
    }

    const checkDocFields = (element, docTypeId) => {
        if (docTypeId == 0) {
            if (element.number && element.issueDate && element.series && element.authority && element.countryOfResidence && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 1) {
            if (element.number && element.issueDate && element.series && element.authority && element.countryOfResidence && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 2) {
            if (element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 3) {
            if (element.number && element.issueDate && element.expiryDate && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 4) {
            if (element.number && element.issueDate && element.expiryDate && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 5) {
            if (element.number && element.issueDate && element.expiryDate && element.series && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 8) {
            if (element.number && element.issueDate && element.expiryDate && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 9) {
            if (element.city && element.countryCode && element.street && element.building)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 10) {
            if (element.number && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 11) {
            if (element.number && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 12) {
            if (element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        return 1;
    }
    useEffect(async () => {
        const currentUser = JSON.parse(localStorage.getItem(id));
        if (currentUser != null) {
            await setTypeDocRequest({ countryCode: currentUser.countryCode, migrantStatus: currentUser.status, target: currentUser.target, id: id });
            // await getTypes();
        }
        setIsLoad(true);
        if (id) {
            let statusDocuments = [];
            await userService.getAddressesById(id, 1).then(_ => {
                let address = _ && _.addresses && _.addresses[0];
                if (address) {
                    // 9 - временая регистрация
                    statusDocuments[9] = checkDocFields(address, 9);
                }

            })
            await userService.getDocumentsById(id, null).then(_ => {
                _.documents.forEach(element => {
                    if (element.documentType === 5) {
                        statusDocuments[100] = checkDocFields(element, 5);
                    }
                    statusDocuments[element.documentType] = checkDocFields(element, element.documentType);
                });
                setCheckDocuments(statusDocuments);
                setIsLoad(false)
            })
        }
    }, [])

    useEffect(async () => {
        checkIsCompleteIdentification();
    }, [checkDocuments, documentTypes]);
    useEffect(async () => {
        getTypes();
    }, [typeDocRequest]);
    if (isLoad) {
        return (<React.Fragment >
        </React.Fragment >)
    }
    return (
        <React.Fragment >
            <Breadcrumb parent="Идентификация" title="Пройти идентификацию пользователя" />
            <div className="card">
                <div className="card-header">
                    <h5>Проверить документы</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Код страны</label>
                                <Typeahead
                                    id="selectedResponsible"
                                    labelKey={option => `${option}`}
                                    options={country}
                                    defaultSelected={typeDocRequest.countryCode}
                                    placeholder="Выберите"
                                    onChange={e => {
                                        setTypeDocRequest({ countryCode: e });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Статус мигранта</label>
                                <Typeahead
                                    id="selectedResponsible"
                                    labelKey={option => option.label}
                                    options={migrantStatuses}
                                    defaultSelected={typeDocRequest.migrantStatus != -1 ? migrantStatuses.filter(x => x.value == typeDocRequest.migrantStatus) : [{ value: -1, label: "" }]}
                                    placeholder="Выберите"
                                    onChange={e => {
                                        setTypeDocRequest({ migrantStatus: e && e[0] && e[0].value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Цель идентификации</label>
                                <Typeahead
                                    id="selectedResponsible"
                                    labelKey={option => `${option.label}`}
                                    options={targetIdentification}
                                    defaultSelected={typeDocRequest.target != -1 ? targetIdentification.filter(x => x.value == typeDocRequest.target) : [{ value: -1, label: "" }]}
                                    placeholder="Выберите"
                                    onChange={e => {
                                        setTypeDocRequest({ target: e && e[0] && e[0].value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <div className="d-flex justify-content-end">
                            <div style={{ marginRight: '10px' }} >
                                <AllertDialog success={false} disabled={false} description={'Вы уверены, что пользователь завершил идентификацию с ошибками?'} nameButton={'Идентификация завершена с ошибками'} onClickAgree={e => updateIdentificationStatus(4)} />
                            </div>
                            <div style={{ marginLeft: '10px' }} >
                                <AllertDialog success={true} disabled={isCompleteIdentificationButton} description={'Вы уверены, что пользователь завершил идентификацию успешно?'} nameButton={'Идентификация завершена успешно'} onClickAgree={e => updateIdentificationStatus(5)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex flex-wrap bd-highlight example-parent">
                        {documentTypes.map((item) => {
                            return (
                                <div className="p-2 bd-highlight col-example" >
                                    <Card onClick={e => clickOnCard(item.value)} >
                                        <CardActionArea>
                                            <CardContent sx={{ padding: "0px" }}>
                                                <div className="card-doc" >
                                                    <div className="card-details-doc" style={{ backgroundImage: `url(${checkDocuments[item.value] == 1 ? backgroundCross : (checkDocuments[item.value] == 2 ? backgroundCheck : backgroundPlus)})` }}>
                                                        <h3>{item.label}</h3>
                                                        <div className="card-line" />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </div>)
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default Identification;


