import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import Country from "../country";
import Switch from "react-switch";
import {countryService} from "../../services/countryService"
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';

const Countries = () => {
    let pageNumber = 1;
    let pageSize = 40;
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER]
    const isReadOnly = !canEditRoles.includes(userRoleName);
    const [datas, setData] = useState([]);
    const onLoadData = async () => {
        let data = await countryService.getList(pageNumber, pageSize).then((data) => {
            if(data){
                setData(data.items);
            }
        });
    }

    useEffect(async () => {
        await onLoadData();
    }, [])

    const handleChange = (checked, event, id) => {
        let form = {
            countryUid: id,
            isActive: checked
        }
        let result = countryService.setStatus(JSON.stringify(form)).then((_) => {
            onLoadData();
        });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Направления"/>
            <div className="card">
                <div className="card-header">
                    <p>Количество направлений переводов: {datas.length}</p>
                </div>
                <div className="card-body datatable-react">
            <div className="container-fluid">
                <div className="row">
                    {datas.map((item, key) =>
                        <div key={key} className="col-xl-4 col-sm-6">
                            <div className="flex my-2">
                                <Switch
                                    onChange={handleChange}
                                    id={item.uid}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    checked={item.isActive}
                                    onColor="#4466f2"
                                    disabled={isReadOnly}
                                    handleDiameter={16}
                                    height={20}
                                    width={35}
                                    className="switch-container"
                                />
                                <Country code={item.country3Code} size={20}/>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Countries;