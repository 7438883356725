import { ApplicationTkbCardForm } from "../components/menu-pages/identification/forms/applicationTkbCardForm"
import { InternationalPassportForm } from "../components/menu-pages/identification/forms/InternationalPassportForm"
import { MigrationCardForm } from "../components/menu-pages/identification/forms/migrationCardForm"
import { PassportForm } from "../components/menu-pages/identification/forms/passportForm"
import { ResidentCardForm } from "../components/menu-pages/identification/forms/residentCardForm"
import { TemporaryRegistrationForm } from "../components/menu-pages/identification/forms/temporaryRegistrationForm"
import { TemporaryResidencePermitForm } from "../components/menu-pages/identification/forms/temporaryResidencePermitForm"
import { TinForm } from "../components/menu-pages/identification/forms/tinForm"
import { TranslatePassportForm } from "../components/menu-pages/identification/forms/translatePassportForm"
import { VisaForm } from "../components/menu-pages/identification/forms/visaForm"

export const getDocTypeLabel = (docType) => {
    switch (docType) {
        case 0:
            return "Паспорт"
        case 1:
            return "Загранпаспорт"
        case 2:
            return "Заверенный перевод загранпаспорта"
        case 3:
            return "Вид на жительство"
        case 4:
            return "Разрешение на временное проживание"
        case 5:
            return "Миграционная карта"
        case 6:
            return "Патент или разрешение на работу"
        case 7:
            return "Трудовой контракт"
        case 8:
            return "Виза"
        case 9:
            return "Временная регистрация"
        case 10:
            return "ИНН"
        case 11:
            return "ИИНН"
        case 12:
            return "Заявление на карту ТКБ"
        case 100:
            return "Миграционная карта (не обязательно)"
    }
}

export const getDocForms = (docType, id, user, oldDoc, oldAddress) => {
    switch (docType) {
        case 0:
            return <PassportForm docTypeId={docType} userId={id} user={user} oldDoc={oldDoc} />
        case 1:
            return <InternationalPassportForm docTypeId={docType} userId={id} user={user} oldDoc={oldDoc} />
        case 2:
            return <TranslatePassportForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 3:
            return <ResidentCardForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 4:
            return <TemporaryResidencePermitForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 5:
            return <MigrationCardForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 6:
            return <MigrationCardForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 7:
            return <MigrationCardForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 8:
            return <VisaForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 9:
            return <TemporaryRegistrationForm docTypeId={docType} userId={id} oldAddress={oldAddress} />
        case 10:
            return <TinForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 11:
            return <TinForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
        case 12:
            return <ApplicationTkbCardForm docTypeId={docType} userId={id} oldDoc={oldDoc} />
    }
}