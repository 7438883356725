import { handlers } from "../helpers/hadlers"

export const sumsubService = {
    sendSumsub,
    getStatus
};

const ssoUrl = process.env.REACT_APP_SSO_URL;

export function getStatus(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(ssoUrl + `api/Identification/kyc/status/` + id, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function sendSumsub(id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: id
    };
    return fetch(ssoUrl + "api/Identification/kyc/document", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
