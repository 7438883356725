import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router";
import countries from "i18n-iso-countries";
import moment from "moment";
import { Button, Col, Row, ButtonGroup, Input, Breadcrumb } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from "react-select";
import { identificationService } from '../../../services/IdentificationService';
import ErrorAlert from '../../alert';
import { country } from '../../../helpers/country';
import { useSetState } from '../../helpers/customHooks';
import { checkFmrBuildingFields } from '../../helpers/validator';
import TextField from '@material-ui/core/TextField';
import DeleteConfirmation from '../../helpers/deleteConfirmation';
import 'moment/locale/ru'
import CustomLoader from '../../../helpers/customLoader';
import { defaultDate } from '../../../helpers/date';
moment.locale('ru')

countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
const FmrEdit = () => {
    const [isLoad, setIsLoad] = useState(false);
    const [errorMesage, setErrorMessage] = useSetState({
        longitude: "",
        latitude: "",
        alpha3Code: "",
        city: "",
        street: "",
        building: "",
        check: ""
    })

    const [fmrEntity, setFmrEntity] = useSetState({
        id: "",
        longitude: "",
        latitude: "",
        alpha3Code: "",
        city: "",
        street: "",
        building: "",
        frame: "",
        postalCode: "",
        createdAt: "",
        createdByFullName: "",
        modifiedByFullName: "",
        modifiedAt: "",
        createdBy: ""

    });
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    const onSaveFmr = async () => {
        setIsLoad(true);
        if (!checkFmrBuildingFields(fmrEntity, errorMesage)) {
            const form = fmrEntity;
            await identificationService.updateFmr(fmrEntity.id, JSON.stringify(form)).then((data) => {
                if (data && data.fmrInput) {
                    setErrors(data.fmrInput);
                }
                else {
                    toast.info(<p>{"Сохранено!"}</p>);
                }
            });
            await onLoad();
        }
        setErrorMessage(errorMesage);
        setIsLoad(false);
    }

    const showDeleteModal = () => {
        setDisplayConfirmationModal(true);
    }

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const deleteFmr = async () => {
        await identificationService.deleteFmr(id)
            .catch(error => {
                toast.error(<p>{error.message}</p>);
                return null;
            });
        toast.info(<p>{"Удалено!"}</p>);
        window.location.href = `${process.env.PUBLIC_URL}/fmrBuildings`;
    }

    const onLoad = async () => {
        setIsLoad(true);
        if (id && id != "edit") {
            await identificationService.getFmrById(id).then(data => {
                setFmrEntity({
                    id: id,
                    longitude: data.longitude,
                    latitude: data.latitude,
                    alpha3Code: data.alpha3Code,
                    city: data.city,
                    street: data.street,
                    building: data.building,
                    frame: data.frame,
                    postalCode: data.postalCode,
                    createdAt: data.createdAt != defaultDate ? moment(data.createdAt).format('LLLL') : null,
                    modifiedAt: data.modifiedAt != defaultDate ? moment(data.modifiedAt).format('LLLL') : null,
                    createdBy: data.createdBy,
                    modifiedBy: data.modifiedBy,
                    modifiedByFullName: data.modifiedByFullName,
                    createdByFullName: data.createdByFullName
                });
            })
            setIsLoad(false)
        }
    }


    useEffect(async () => {
        await onLoad();
    }, [id])

    if (isLoad) {
        return <CustomLoader />
    }
    return (
        <React.Fragment >
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={deleteFmr} hideModal={hideConfirmationModal} />
            <Breadcrumb parent="Фмр отделение" title="Редактировать фмр отделение" />
            {errors == undefined ? <></> : <ErrorAlert data={errors} setErrors={setErrors} />}
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">ФМР</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript"
                                            data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a><a
                                                className="card-options-remove" href="javascript" data-toggle="card-remove"><i
                                                className="fe fe-x" /></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group">
                                            <label className="form-label">Дата создания записи</label>
                                            <input className="form-control" defaultValue={fmrEntity.createdAt || ' '}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        {fmrEntity.modifiedAt ?
                                            <div className="form-group">
                                                <label className="form-label">Дата изменения записи</label>
                                                <input className="form-control" defaultValue={fmrEntity.modifiedAt || ' '}
                                                    placeholder="your-email@domain.com" readOnly />
                                            </div> : <></>}
                                        <div className="form-group">
                                            <label className="form-label">Кто создал запись</label>
                                            <div className="form-control"
                                                readOnly><Link to={"/user/edit/" + fmrEntity.createdBy}>{fmrEntity.createdByFullName}</Link></div>
                                        </div>
                                        {fmrEntity.modifiedBy ?
                                            <div className="form-group">
                                                <label className="form-label">Кто изменил запись</label>
                                                <div className="form-control"
                                                    readOnly><Link to={"/user/edit/" + fmrEntity.modifiedBy}>{fmrEntity.modifiedByFullName}</Link></div>
                                            </div> : <></>}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Долгота</label>
                                                {errorMesage.longitude != "" ?
                                                    (<TextField error helperText={errorMesage.longitude} variant="outlined" size="small" className="form-control"
                                                        onChange={e => { setFmrEntity({ longitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.longitude} type="number" id="Longitude" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control"
                                                        onChange={e => { setFmrEntity({ longitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.longitude} type="number" id="Longitude" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Широта</label>
                                                {errorMesage.latitude != "" ?
                                                    (<TextField error helperText={errorMesage.latitude} variant="outlined" size="small" className="form-control"
                                                        onChange={e => { setFmrEntity({ latitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.latitude} type="number" id="Latitude" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control"
                                                        onChange={e => { setFmrEntity({ latitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.latitude} type="number" id="Latitude" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Код страны в 3 символа</label>
                                                <Typeahead
                                                    id="selectedResponsible"
                                                    isInvalid={errorMesage.alpha3Code != ""}
                                                    labelKey={option => `${option}`}
                                                    options={country}
                                                    placeholder="Выберите"
                                                    defaultSelected={fmrEntity.alpha3Code !== null ? [fmrEntity.alpha3Code] : []}
                                                    onChange={e => {
                                                        setFmrEntity({ alpha3Code: e })
                                                    }}
                                                />
                                                {errorMesage.alpha3Code != "" ? <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense" id="Street-helper-text">{errorMesage.alpha3Code}</p> : <></>}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Город</label>
                                                {errorMesage.city != "" ?
                                                    (<TextField error helperText={errorMesage.city} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                                        onChange={e => { setFmrEntity({ city: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.city} type="text" id="City" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                                        onChange={e => { setFmrEntity({ city: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.city} type="text" id="City" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Улица</label>
                                                {errorMesage.street != "" ?
                                                    (<TextField error helperText={errorMesage.street} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                                        onChange={e => { setFmrEntity({ street: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.street} type="text" id="Street" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                                        onChange={e => { setFmrEntity({ street: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.street} type="text" id="Street" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Дом</label>
                                                {errorMesage.building != "" ?
                                                    (<TextField error helperText={errorMesage.building} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 5 }}
                                                        onChange={e => { setFmrEntity({ building: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.building} type="text" id="Building" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 5 }}
                                                        onChange={e => { setFmrEntity({ building: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.building} type="text" id="Building" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Корпус</label>
                                                <input name="Frame" className="form-control" type="text"
                                                    defaultValue={fmrEntity.frame}
                                                    onChange={e => { setFmrEntity({ frame: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Индекс</label>
                                                <input name="PostalCode" className="form-control" type="number"
                                                    defaultValue={fmrEntity.postalCode}
                                                    onChange={e => { setFmrEntity({ postalCode: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <Col>
                                        <div className="card-footer text-left">
                                            <button className="btn btn-danger" onClick={e => showDeleteModal()}>Удалить</button>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="card-footer text-right">
                                            <button className="btn btn-primary" onClick={event => onSaveFmr()} type="submit">Сохранить</button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};



export default FmrEdit;


