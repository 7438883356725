export const operationStatuses =
    [
        { value: 0, label: "Все" },
        { value: 1, label: "Не оплачен" },
        { value: 2, label: "Оплачен" },
        { value: 5, label: "Зачислен" },
        { value: 6, label: "Ошибка" },
        { value: 4, label: "Возврат" },
        { value: 7, label: "Возврат в ручную" },
        { value: 10, label: "Ошибка при оплате" },
        { value: 8, label: "Ошибка возврата на карту" },
        { value: 11, label: "Платеж отменяется" },
        { value: 9, label: "Ошибка на стороне Юнистрим" }
    ];

    export const operationTypes =
    [
        { value: null, label: "Все" },
        { value: 0, label: "С карты в наличные" },
        { value: 1, label: "С карты на карту" }
    ];

    export const contractors =
    [
        { value: "", label: "Все" },
        { value: "W1", label: "W1" },
        { value: "MonetaAndContact", label: "Монета/Контакт" }
    ];

export const getStatusText = (statusNum) => {
    switch (statusNum) {
        case "Created":
            return "Не оплачен"
        case "NotPaid":
            return "Не оплачен"
        case "Success":
            return "Зачислен"
        case "Failed":
            return "Ошибка"
        case "Paid":
            return "Оплачено"
        case "CancelError":
            return "Ошибка возврата на карту"
        case "Canceled":
            return "Возврат"
        case "Refund":
            return "Возврат в ручную"
        case "PaymentProcessError":
            return "Ошибка при оплате"
        case "PayoutProcessError":
            return "Ошибка на стороне Юнистрим"
        case "Canceling":
            return "Платеж отменяется"
        case "InternalW1ErrorStatus":
            return "Внутрення ошибка в1."
    }
}
export const getStatusColor = (operationStatus) => {
    switch (operationStatus) {
        case "Created":
            return "warning"
        case "NotPaid":
            return "warning"
        case "Paid":
            return "primary"
        case "Canceled":
            return "warning"
        case "Success":
            return "success"
        case "Failed":
            return "danger"
        case "CancelError":
            return "danger"
        default:
            return "danger"
    }
};

export const getPrecentStatus = (statusNum) => {
    switch (statusNum) {
        case "Created":
            return 0
        case "RejectedByScoring":
            return 10
        case "ConfirmedByScoring":
            return 2
        case "RejectedByRiskAnalysis":
            return 10
        case "ConfirmedByRiskAnalysis":
            return 4
        case "RejectedByZaprosto":
            return 10
        case "ConfirmedByZaprosto":
            return 6
        case "TransferredToCourier":
            return 8
        case "Closed":
            return 10
        case "Rejected":
            return 10
    }
}
export const getNextStatus = (statusNum) => {
    switch (statusNum) {
        case "Created":
            return "Отправить на проверку в скоринг"
        case "RejectedByScoring":
            return "Отклонена скорингом"
        case "ConfirmedByScoring":
            return "Отправить на проверку в анализ рисков"
        case "RejectedByRiskAnalysis":
            return "Отклоена анализом рисков"
        case "ConfirmedByRiskAnalysis":
            return "Отправить на проверку в скоринг запросто"
        case "RejectedByZaprosto":
            return "Отклоена скорингом запросто"
        case "ConfirmedByZaprosto":
            return "Отправить заказ курьеру"
        case "TransferredToCourier":
            return "Клиент получил заказ"
        case "Closed":
            return "Заявка закрыта"
        case "Rejected":
            return "Заявка отклонена"
    }
}
export const orderStatuses =
    [
        { value: 0, label: "Создана" },
        { value: 1, label: "Отклонена скорингом" },
        { value: 2, label: "Подтвержден скорингом" },
        { value: 3, label: "Отклоена анализом рисков" },
        { value: 4, label: "Подтвержден анализом рисков" },
        { value: 5, label: "Отклоена скорингом запросто" },
        { value: 6, label: "Подтверждена скорингом запросто" },
        { value: 7, label: "Передано курьеру" },
        { value: 8, label: "Закрыта" },
        { value: 9, label: "Отклонена" },
    ];

export const getOrderStatusText = (statusNum) => {
    switch (statusNum) {
        case "Created":
            return "Создана"
        case "RejectedByScoring":
            return "Отклонена скорингом"
        case "ConfirmedByScoring":
            return "Подтвержден скорингом"
        case "RejectedByRiskAnalysis":
            return "Отклоена анализом рисков"
        case "ConfirmedByRiskAnalysis":
            return "Подтвержден анализом рисков"
        case "RejectedByZaprosto":
            return "Отклоена запросто"
        case "ConfirmedByZaprosto":
            return "Подтверждена запросто"
        case "TransferredToCourier":
            return "Передано курьеру"
        case "Closed":
            return "Закрыта"
        case "Rejected":
            return "Отклонена"
    }
};

export const getOrderStateText = (stateNum) => {
    switch (stateNum) {
        case "InZam":
            return "В заме"
        case "InScoring":
            return "В скоринге"
        case "InRiskAnalysis":
            return "В анализе рисков"
        case "InZaprosto":
            return "В запросто"
    }
};
export const getOrderStateValue = (statusNum) => {
    switch (statusNum) {
        case "Created":
            return "InScoring"
        case "ConfirmedByScoring":
            return "InRiskAnalysis"
        case "ConfirmedByRiskAnalysis":
            return "InZaprosto"
        default:
            return "InZam"
    }
};
export const getOrderStatusColor = (operationStatus) => {
    switch (operationStatus) {
        case "Created":
            return "warning"
        case "Closed":
            return "success"
        case "ConfirmedByScoring":
            return "success"
        case "ConfirmedByRiskAnalysis":
            return "success"
        case "ConfirmedByZaprosto":
            return "success"
        case "TransferredToCourier":
            return "primary"
        default:
            return "danger"
    }
};

export const allAppIdentificationStatuses = [
    { value: 0, label: "Все" },
    { value: 1, label: "В ожидании" },
    { value: 2, label: "Курьер выбран" },
    { value: 3, label: "Встреча назначена" },
    { value: 4, label: "Идентификация не пройдена" },
    { value: 5, label: "Идентификация прошла успешно" },
    { value: 6, label: "Закрыта по просьбе пользователя" }
]

export const courierFilterAppIdentificationStatuses =
    [
        { value: 0, label: "Все" },
        { value: 2, label: "Курьер выбран" },
        { value: 3, label: "Встреча назначена" }
    ];

export const appIdentificationStatuses =
    [
        { value: 1, label: "В ожидании" },
        { value: 6, label: "Закрыто по просьбе пользователя" }
    ];

export const partialIdentificationStatuses =
    [
        { value: 0, label: "Все" },
        { value: 1, label: "В ожидании" },
        { value: 2, label: "Идентификация провалена" },
        { value: 3, label: "Идентификация успешно пройдена" }

    ];