import { handlers } from "../helpers/hadlers"

export const userService = {
    edit,
    getById,
    getList,
    getListBySource,
    getDocumentsById,
    getAddressesById
};

const apiUrl = process.env.REACT_APP_API_URL;

export function getList(fullNameSearch, phoneSearch, role, page){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/users/base?request.filter.searchByName=${fullNameSearch}&request.filter.searchByPhone=${phoneSearch}&request.filter.searchByRole=${role}&request.filter.pageNumber=${page}&request.filter.pageSize=20`, requestOptions)
    .then(handlers.handleResponse)
    .then(_ => {
        return _;
    });
}

export function getListBySource(fullNameSearch,phoneSearch, page, source){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/users/base?request.filter.source=${source}&request.filter.searchByName=${fullNameSearch}&request.filter.searchByPhone=${phoneSearch}&request.filter.pageNumber=${page}&request.filter.pageSize=20`, requestOptions)
    .then(handlers.handleResponse)
    .then(_ => {
        return _;
    });
}

export function getById(userUid) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "api/v2/users/details/" + userUid, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function edit(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/v2/users/details", requestOptions)
        .then(handlers.userEditHandleResponse)
        .then(_ => {
            return _;
        });
}

export function getDocumentsById(userUid, docType) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    let typeRequest = docType == '' || docType == null || docType == undefined ? '' : `?docType=${docType}`;
    return fetch(apiUrl + "api/v2/users/documents/" + userUid + typeRequest, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getAddressesById(userUid, addressType) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    let typeRequest = addressType == '' || addressType == null || addressType == undefined ? '' : `?addressType=${addressType}`;
    return fetch(apiUrl + "api/v2/users/addresses/" + userUid + typeRequest, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
