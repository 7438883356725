import CountUp from "react-countup";
import BarChartIcon from '@mui/icons-material/BarChart';


const StatisticChart = ({num, name, unit, visibleStatus, classButton}) => {
    return (
        <div className={classButton ? 'card card-button' : 'card'}>
            <div className={visibleStatus ? 'card-active' : ''}>
                <div className="card-body" >
                    <div className="media chart-university">
                        <div className="media-body" >
                            <h3 className="mb-0">
                                <CountUp className="counter" end={num} suffix={unit} />
                            </h3>
                            <p>{name}</p>
                        </div>
                        <div className="small-bar">
                            <BarChartIcon color="primary" sx={{ fontSize: 50 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
    
export default StatisticChart;