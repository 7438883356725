import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router";
import countries from "i18n-iso-countries";
import moment from "moment";
import { Button, Col, Row, ButtonGroup, Input, Breadcrumb } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from "react-select";
import { identificationService } from '../../../services/IdentificationService';
import ErrorAlert from '../../alert';
import { country } from '../../../helpers/country';
import { useSetState } from '../../helpers/customHooks';
import { allAppIdentificationStatuses, appIdentificationStatuses, appIdentificationStatusesForCourier, filterAppIdentificationStatuses } from '../../../helpers/statuses';
import { authService } from '../../../services/authService';
import TextField from '@material-ui/core/TextField';
import { checkAppIdentificationFields } from '../../helpers/validator';
import { width } from '@mui/system';
import 'moment/locale/ru'
import CustomLoader from '../../../helpers/customLoader';
import { defaultDate } from '../../../helpers/date';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../helpers/roleHelper';
import { Feedback } from '../../../constant';
moment.locale('ru')

const AppIdentificationEdit = () => {
    const [isLoad, setIsLoad] = useState(true);
    const [userCourier, setCourier] = useState({
        value: 0,
        label: <p style={{ margin: 18 }}></p>
    })
    const [couriers, setCouriers] = useState([]);
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER, ROLES.SUPPORT, ROLES.IDENTIFICATIONADMINISTRATOR];
    const isCanIdentification = userRoleName == ROLES.IDENTIFICATIONAGENT;
    const isReadOnly = !canEditRoles.includes(userRoleName);


    const [errorMesage, setErrorMessage] = useSetState({
        id: "",
        longitude: "",
        latitude: "",
        alpha3Code: "",
        city: "",
        street: "",
        building: "",
        check: ""
    })

    const [appIdentificationEntity, setAppIdentificationEntity] = useSetState({
        id: "",
        alpha3Code: "",
        city: "",
        street: "",
        building: "",
        frame: "",
        meetingTime: "",
        postalCode: "",
        userComment: "",
        createdAt: "",
        modifiedAt: "",
        createdBy: "",
        createdByFullName: "",
        modifiedByFullName: "",
    });
    const [userEntity, setUserEntity] = useSetState({
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: ""
    })

    const [identificationStatus, setIdentificationStatus] = useState(0)
    const [courierId, setCourierId] = useState('');
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const aggreedToMeetStatus = 3;

    const onSaveAppIdentification = async () => {
        setIsLoad(true);
        if (!checkAppIdentificationFields(appIdentificationEntity, errorMesage)) {

            const form = appIdentificationEntity;
            await identificationService.updateAppIdentification(appIdentificationEntity.id, JSON.stringify(form)).then((data) => {
                if (data && data.appIdentificationInput) {

                    setErrors(data.appIdentificationInput);
                }
                else {

                    toast.info(<p>{"Сохранено!"}</p>);
                }
            });
            await onLoad();
        }
        setErrorMessage(errorMesage);
        setIsLoad(false);
    }

    const onStartIdentification = async (courierId) => {
        window.location.href = `${process.env.PUBLIC_URL}/identification/` + userEntity.id;
    }

    const onLoad = async () => {
        setIsLoad(true);
        if (id && id != "edit") {
            await identificationService.getAppIdentificationById(id).then(data => {
                setAppIdentificationEntity({
                    id: id,
                    alpha3Code: data.address?.alpha3CodeCountry,
                    city: data.address?.city,
                    street: data.address?.street,
                    building: data.address?.building,
                    house: data.address?.house,
                    frame: data.address?.frame,
                    postalCode: data.address?.postalCode,
                    meetingTime: data.meetingTime ? moment(data.meetingTime).format('YYYY-MM-DDTHH:MM') : "",
                    userComment: data.userComment,
                    createdAt: data.createdAt != defaultDate ? moment(data.createdAt).format('LLLL') : null,
                    modifiedAt: data.modifiedAt != defaultDate ? moment(data.modifiedAt).format('LLLL') : null,
                    modifiedBy: data.modifiedBy,
                    createdBy: data.createdBy,
                    modifiedByFullName: data.modifiedByFullName,
                    createdByFullName: data.createdByFullName
                });
                setUserEntity({
                    id: data.userId,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber
                });
                setIdentificationStatus(data.status);
                setCourierId(data.courierId)
            })
            await getCouriers();
        }
        setIsLoad(false)
    }

    const onChangeStatus = async (status) => {
        identificationService.updateAppIdentificationStatus(appIdentificationEntity.id, JSON.stringify({ status: status })).then(async (data) => {
            if (data && data.appIdentificationInput) {
                setErrors(data.appIdentificationInput);
            }
            else {
                toast.info(<p>{"Статус успешно изменен!"}</p>);
            }
            await onLoad();
        });
    }

    const onChangeCourier = async (courierId) => {
        identificationService.assignCourierToAppIdentification(JSON.stringify({ appIdentificationId: appIdentificationEntity.id, courierId })).then(async (data) => {
            if (data && data.appIdentificationInput) {
                setErrors(data.appIdentificationInput);
            }
            else {
                toast.info(<p>{"Курьер успешно назначен!"}</p>);
            }
            await onLoad();

        });
    }

    useEffect(async () => {
        await onLoad();
    }, [id])
    useEffect(async () => {
    }, [identificationStatus])

    const getCouriers = async () => {
        let array = [];
        await identificationService.getFreeCouriers().then(async (data) => {
            if (data) {
                data.forEach(courier => {
                    array.push({ value: courier.id, label: courier.fullName })
                });
                setCouriers(array);
            }
        });
        checkAndSetUserCourier();
    }

    const checkAndSetUserCourier = async () => {
        if (!courierId) {
            setCourier({
                value: '',
                label: ''
            })
            return;
        }
        await couriers.forEach(courier => {
            if (courier.value == courierId) {
                setCourier({
                    value: courier.value,
                    label: courier.label
                })
            }
        });

    }

    useEffect(async () => {
        await getCouriers();
    }, [userEntity])

    useEffect(async () => {
        await checkAndSetUserCourier();
    }, [couriers])

    if (isLoad) {
        return <CustomLoader />
    }
    return (
        <React.Fragment >

            <Breadcrumb parent="Заявка на идентификацию" title="Редактировать заявку на идентификацию" />
            {errors == undefined ? <></> : <ErrorAlert data={errors} setErrors={setErrors} />}
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Заявка</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript"
                                            data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a><a
                                                className="card-options-remove" href="javascript" data-toggle="card-remove"><i
                                                className="fe fe-x" /></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group">
                                            <label className="form-label">ФИО</label>
                                            <input className="form-control" defaultValue={userEntity.firstName + ' ' + userEntity.lastName + ' ' + userEntity.middleName}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Номер телефона</label>
                                            <input className="form-control" defaultValue={userEntity.phoneNumber || ' '}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Дата создания записи</label>
                                            <input className="form-control" defaultValue={appIdentificationEntity.createdAt || ' '}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        {appIdentificationEntity.modifiedAt ?
                                            <div className="form-group">
                                                <label className="form-label">Дата изменения записи</label>
                                                <input className="form-control" defaultValue={appIdentificationEntity.modifiedAt || ' '}
                                                    placeholder="your-email@domain.com" readOnly />
                                            </div> : <></>}
                                        <div className="form-group">
                                            <label className="form-label">Кто создал запись</label>
                                            <div className="form-control"
                                                readOnly>{!isReadOnly ? <Link to={"/user/edit/" + appIdentificationEntity.createdBy}>{appIdentificationEntity.createdByFullName}</Link> : appIdentificationEntity.createdByFullName}</div>
                                        </div>
                                        <div className="form-group">
                                            {appIdentificationEntity.modifiedBy ?
                                                <div className="form-group">
                                                    <label className="form-label">Кто изменил запись</label>
                                                    <div className="form-control"
                                                        readOnly>{!isReadOnly ? <Link to={"/user/edit/" + appIdentificationEntity.modifiedBy}>{appIdentificationEntity.modifiedByFullName}</Link> : appIdentificationEntity.modifiedByFullName}</div>
                                                </div> : <></>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Адрес</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript"
                                            data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a><a
                                                className="card-options-remove" href="javascript" data-toggle="card-remove"><i
                                                className="fe fe-x" /></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Город</label>
                                                {errorMesage.city != "" ?
                                                    (<TextField error helperText={errorMesage.city} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }} readOnly={isReadOnly}
                                                        onChange={e => setAppIdentificationEntity({ city: e.target.value })} value={appIdentificationEntity.city} type="text" id="City" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }} readOnly={isReadOnly}
                                                        onChange={e => setAppIdentificationEntity({ city: e.target.value })} value={appIdentificationEntity.city} type="text" id="City" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Улица</label>
                                                {errorMesage.street != "" ?
                                                    (<TextField error helperText={errorMesage.street} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }} readOnly={isReadOnly}
                                                        onChange={e => setAppIdentificationEntity({ street: e.target.value })} value={appIdentificationEntity.street} type="text" id="Street" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }} readOnly={isReadOnly}
                                                        onChange={e => setAppIdentificationEntity({ street: e.target.value })} value={appIdentificationEntity.street} type="text" id="Street" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Дом</label>
                                                {errorMesage.building != "" ?
                                                    (<TextField error helperText={errorMesage.building} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 5 }} readOnly={isReadOnly}
                                                        onChange={e => setAppIdentificationEntity({ building: e.target.value })} value={appIdentificationEntity.building} type="text" id="Building" />)
                                                    : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 5 }} readOnly={isReadOnly}
                                                        onChange={e => setAppIdentificationEntity({ building: e.target.value })} value={appIdentificationEntity.building} type="text" id="Building" />)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Корпус</label>
                                                <input name="Frame" className="form-control" type="text"
                                                    readOnly={isReadOnly}
                                                    defaultValue={appIdentificationEntity.frame}
                                                    onChange={e => setAppIdentificationEntity({ frame: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Комментарий по заявке</label>
                                                <input name="UserComment" className="form-control" type="text"
                                                    readOnly={isReadOnly}
                                                    defaultValue={appIdentificationEntity.userComment}
                                                    onChange={e => setAppIdentificationEntity({ userComment: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                {!(courierId && courierId != "") ? (<></>) : (
                                                    <div>
                                                        <label className="form-label">Время встречи</label>
                                                        {isReadOnly ?
                                                            (<input name="MeetingTime" className="form-control" type="text" value={appIdentificationEntity.meetingTime ? moment(appIdentificationEntity.meetingTime).format('DD.MM.YYYY HH:MM') : ""}
                                                                readOnly={true} />)
                                                            :
                                                            (
                                                                <Input name="ExpiryDate" className="form-control" type="datetime-local"
                                                                    min={new Date().toISOString().slice(0, -8)}
                                                                    defaultValue={appIdentificationEntity.meetingTime}
                                                                    onChange={e => setAppIdentificationEntity({ meetingTime: e.target.value })} />
                                                            )
                                                        }
                                                    </div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isCanIdentification && identificationStatus === aggreedToMeetStatus ?
                                    <Row>
                                        <Col>
                                            <div className="card-footer text-center">
                                                <button className="btn btn-success" onClick={event => onStartIdentification()} type="submit">Провести идентификацию</button>
                                            </div>
                                        </Col> : <></>

                                    </Row> : <></>
                                }
                                {!isReadOnly ?
                                    <Row>
                                        <Col>
                                            <div className="card-footer text-center">
                                                <button className="btn btn-primary" onClick={event => onSaveAppIdentification()} type="submit">Сохранить</button>
                                            </div>
                                        </Col>
                                    </Row> : <></>
                                }
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Курьер</label>
                                                <Select onChange={e => onChangeCourier(e.value)}
                                                    isDisabled={isReadOnly}
                                                    options={couriers}
                                                    autosize={true}
                                                    id="courier"
                                                    defaultValue={{ label: userCourier.label, value: userCourier.value }}
                                                    name="courier" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Статус</label>
                                                <Select onChange={e => {
                                                    onChangeStatus(e.value);
                                                }}
                                                    isDisabled={isReadOnly}
                                                    defaultValue={allAppIdentificationStatuses[identificationStatus]}
                                                    placeholder="Статус" options={appIdentificationStatuses}
                                                    name="status" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default AppIdentificationEdit;


