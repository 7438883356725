import { Button } from "reactstrap";
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from '../../breadcrumb';
import { getDateFull } from "../../../helpers/date";
import { ReactComponent as SmallLogo } from "../../../assets/images/logo/small-logo-zamzam.svg";
import Country from "../../country";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import DataTableComponent from "../../dataTableComponent";
import Loader from "../../loader";
import PaginationComponent from "../../pagination";
import StatisticChart from "../../staistic-chart";
import {
    RotateCcw
} from 'react-feather';
import {
    ArrowRight
} from 'react-feather';

import { operationService } from "../../../services/operationService";
import { operationColumns } from "../../../helpers/tableColumns";
import { getStatusColor } from "../../../helpers/statuses";
import { getStatusText } from "../../../helpers/statuses";

const OperationsModalComponent = (props) => {
    const pageSize = 20;
    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [page, setPage] = useState(1);
    const [countElements, setCountElements] = useState(0);
    const { id } = useParams();
    const [offset, setOffset] = useState(0);
    const [datas, setData] = useState([]);

    const [visibleStatus, setVisibleStatus] = useState(true);

    let dataTable = [];
    // let userOperations = [];

    const onLoadData = async () => {
        if (id) {
            let data = await operationService.getAllOperationByUserId(id, page)
                .then((data) => {
                    if (data) {
                        setCountElements(data.pagination.totalRecords);
                        setData(data);
                    }
                });
        }
    };

    const update = async (e) => {
        setPage(e / pageSize + 1);
        await onLoadData();
    };

    useEffect(async () => {
        await onLoadData();
    }, [page])


    if (datas.items) {
        datas.items.forEach(operation => {
            const obj = {
                date: operation.number ? <>{getDateFull(operation.createdUtcDateTime)}<br /><SmallLogo />{operation.uniqueNumber}<br />{operation.uid} <br />Юнистрим - {operation.number}</> :
                <>{getDateFull(operation.createdUtcDateTime)}<br /><SmallLogo />{operation.uniqueNumber}<br />{operation.uid}</>,
                country: <Country code={operation.country2Code?.toLowerCase()} />,
                send: <>
                    <p>{operation.who.lastName} {operation.who.firstName}<br />
                        {operation.who.middleName}
                    </p>
                    <Link
                        to={"/user/" + operation.who.uid}>{operation.who.phoneNumber}</Link>
                </>,
                get: <>
                    <p>{operation.whom?.lastName} {operation.whom?.firstName}<br />
                        {operation.whom?.middleName}
                    </p>
                    <Link
                        to={"/user/" + operation.whom?.uid}>{operation.whom?.phoneNumber}</Link>
                </>,
                currency: <>{operation.acceptedCurrency} - {operation.withdrawCurrency} </>,
                sum: operation.amount + " ₽ - " + operation.currencyAmount + (operation.withdrawCurrency === "USD" ? " $" : ""),
                rate: operation.rate + " ₽",
                commission: operation.totalCommissionAmount + " ₽",
                earn: operation.ourFeeAmount + operation.ourFixCommission + " ₽",
                status: <div className="flex"><span
                    className={`badge badge-pill badge-${getStatusColor(operation.operationStatus)}`}>{getStatusText(operation.operationStatus)}</span><RotateCcw
                        style={operation.operationStatus !== "Paid" ? { display: "none" } : { display: "block" }} className="ml-1"
                        width={15} /></div>

            };
            dataTable = [...dataTable, obj];

        })
    }

    useEffect(() => {
        setIsValid(name !== "");
    }, [name])

    const history = useHistory();

    const back = () => {
        history.goBack();
    }

    return (
        <Fragment>
            <Breadcrumb parent="Пользователь" title="Операции" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-sm-6" onClick={() => setVisibleStatus(!visibleStatus)} >
                                {visibleStatus ? (
                                    <StatisticChart num={datas.items ? datas.pagination.totalRecords : 0}
                                        name="Кол-во операций" />) : (
                                    <div className="col-sm-12">
                                        <StatisticChart
                                            num={datas.items ? datas.totalSuccessfulCount : 0}
                                            name="Успешно" />
                                        <StatisticChart
                                            num={datas.items ? datas.totalFailedCount : 0}
                                            name="Неуспешно" />
                                    </div>
                                )}

                            </div>
                            <div className="col-sm-6" onClick={() => setVisibleStatus(!visibleStatus)} >
                                {visibleStatus ? (
                                    <StatisticChart
                                        num={datas.items ? datas.totalSum : 0}
                                        unit="₽"
                                        name="Сумма операций" />) : (
                                    <div className="col-sm-12">
                                        <StatisticChart
                                            num={datas.items ? datas.totalSuccessfulSum : 0}
                                            name="Успешно" />
                                        <StatisticChart
                                            num={datas.items ? datas.totalFailedSum : 0}
                                            name="Неуспешно" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-sm-6">
                                <StatisticChart
                                    num={datas.items ? datas.totalComission : 0}
                                    unit="₽"
                                    name="Сумма комиссии" />

                            </div>
                            <div className="col-sm-6">
                                <StatisticChart
                                    num={datas.items ? datas.appCommission : 0}
                                    unit="₽"
                                    name="Доход Zamzam" />

                            </div>

                        </div>
                    </div>
                </div>
                {props.children}

                {datas.items ?
                    <>
                        <div className="table-mobile">
                            {datas.items ? datas.items.map(operation =>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="flex space-between mb-3">
                                            <div>{getDateFull(operation.createdUtcDateTime)}
                                            </div>

                                            <Country code={operation.countryCode} />
                                            <span
                                                className={`badge badge-pill badge-${getStatusColor(operation.operationStatus)}`}>{getStatusText(operation.operationStatus)}</span>
                                        </div>

                                        <div className="flex space-between">
                                            <div>
                                                <p>{operation.who.lastName}<br /> {operation.who.firstName}<br />
                                                    {operation.who.middleName}
                                                </p>

                                            </div>
                                            <div className="flex-centre">
                                                <>{operation.amount + " ₽ "}</>
                                                <ArrowRight />
                                                <>{operation.currencyAmount + (operation.withdrawCurrency === "USD" ? " $" : "")}</>

                                            </div>
                                            <div>
                                                <p>{operation.whom?.lastName}<br /> {operation.whom?.firstName}<br />
                                                    {operation.whom?.middleName}
                                                </p>

                                            </div>

                                        </div>
                                        <div className="flex space-between">
                                            <Link
                                                to={"/user/" + operation.who.uid}>{operation.who.phoneNumber}</Link>
                                            <Link
                                                to={"/user/" + operation.whom?.uid}>{operation.whom?.phoneNumber}</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                                : <Loader />
                            }
                        </div>

                        <div className="table-main">
                            <DataTableComponent data={dataTable} columns={operationColumns} />
                        </div>
                    </>
                    : <Loader />
                }
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }
                        }
                        totalArticles={datas.items ? datas.pagination.totalRecords : 6}
                        articlesPerPage={pageSize}
                    /> : <></>
                }
            </div>
            <div style={{ textAlign: "right", marginRight: "50px" }}>
                <Button disabled={isLoading} style={{ marginBottom: "20px" }} onClick={e => back()}>Закрыть</Button>
            </div>
        </Fragment>
    )
}
export default OperationsModalComponent;
