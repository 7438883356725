import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { partnerService } from "../../services/partnerService"
import StatisticChart from '../staistic-chart';
import { useHistory } from 'react-router';

const PartnerStatistics = () => {
    const [datas, setData] = useState([]);
    const onLoadData = async () => {
        let data = await partnerService.getList().then((data) => {
            if (data) {
                setData(data);
            }
        });
    }
    const history = useHistory();

    const openPage = (source) =>{
        history.push('/aboutPartner/' + source)
    }

    useEffect(async () => {
        await onLoadData();

    }, [])

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Партнеры" />
            <div class="container">
                {
                    datas.map((item) => {
                        return (
                            <div style={{marginBottom: "30px"}}>
                                <Card onClick={e => openPage(item.source)} >
                                    <CardActionArea>
                                        <CardContent sx={{ height: "300px" }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                        <Typography fontSize="45px" component="div" align="left" marginTop="1cm">
                                                            {item.name}
                                                        </Typography>
                                                </div>
                                                <div className="col-sm-6">
                                                    <StatisticChart
                                                        num={item.countUsers}
                                                        name="Кол-во пользователей" />

                                                </div>
                                                <div className="col-sm-6">
                                                    <StatisticChart
                                                        num={item.countOperations}
                                                        name="Кол-во операций" />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    );
};

export default PartnerStatistics;