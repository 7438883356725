import { handlers } from "../helpers/hadlers";

const apiUrl = process.env.REACT_APP_API_URL;

export const countryService = {
    setStatus,
    getList
};

export function getList(pageNumber, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/countries?request.filter.pageNumber=${pageNumber}&request.filter.pageSize=${pageSize}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function setStatus(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/countries/status", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
