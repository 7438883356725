import jwt_decode from "jwt-decode";
import {
    Home,
    DollarSign,
    Users,
    Flag,
    UserCheck,
    BookOpen,
    AtSign,
    Book
} from 'react-feather';
import { ROLES } from "../../helpers/roleHelper";

const accessibleRoles = [ROLES.ADMIN, ROLES.TOPMANAGER, ROLES.DEVELOPER, ROLES.TESTER]

export const MENUITEMS = () => {
    const roleName = jwt_decode(localStorage.getItem("token")).role;
    if (accessibleRoles.includes(roleName))
        return ADMINMENUITEMS;
    else if (roleName == ROLES.SUPPORT)
        return SUPPORTMENUITEMS;
    else if (roleName == ROLES.IDENTIFICATIONAGENT)
        return COURIERMENUITEMS;
    else if (roleName == ROLES.AGENTFMR)
        return FMRAGENTMENUITEMS;
    else if (roleName == ROLES.IDENTIFICATIONADMINISTRATOR)
        return IDENTIFICATIONADMINISTRATORMENUITEMS;
}

const ADMINMENUITEMS = [
    {
        title: 'Дашбоард', icon: Home, type: 'link', path: '/dashboard', active: false
    },
    {
        title: 'Пользователи', icon: Users, type: 'link', path: '/users', active: false
    },
    {
        title: 'Переводы', icon: DollarSign, type: 'link', path: '/operations', active: false
    },
    {
        title: 'Направления', icon: Flag, type: 'link', path: '/countries', active: false
    },
    {
        title: 'Администрирование', icon: BookOpen, type: 'link', path: '/admin', active: false
    },
    {
        title: 'Партнеры', icon: AtSign, type: 'link', path: '/partnerStatistics', active: false
    },
    // {
    //     title: 'Заявки', icon: AtSign, type: 'link', path: '/orders', active: false
    // },
    {
        title: 'Роли', icon: Users, type: 'link', path: '/roles', active: false
    },
    {
        title: 'ФМР отделения', icon: Home, type: 'link', path: '/fmrBuildings', active: false
    },
    {
        title: 'Идентификация', icon: Book, type: 'link', path: '/appIdentifications', active: false
    },
    {
        title: 'Частичная идентификация', icon: Book, type: 'link', path: '/partialIdentifications', active: false
    }
]

const COURIERMENUITEMS = [
    {
        title: 'Идентификация', icon: Book, type: 'link', path: '/appIdentifications', active: false
    }
]

const FMRAGENTMENUITEMS = [
    {
        title: 'Пользователи', icon: Users, type: 'link', path: '/users', active: false
    }
]

const SUPPORTMENUITEMS = [
    {
        title: 'Пользователи', icon: Users, type: 'link', path: '/users', active: false
    },
    {
        title: 'Переводы', icon: DollarSign, type: 'link', path: '/operations', active: false
    }
]

const IDENTIFICATIONADMINISTRATORMENUITEMS = [
    {
        title: 'Пользователи', icon: Users, type: 'link', path: '/users', active: false
    },
    {
        title: 'Идентификация', icon: Book, type: 'link', path: '/appIdentifications', active: false
    }
]