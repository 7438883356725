import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import PaginationComponent from "../pagination";
import { getDateFull, getDateOnly } from "../../helpers/date";
import countries from "i18n-iso-countries";
import { Link } from "react-router-dom";
import DataTableComponent from "../dataTableComponent";
import { userService } from "../../services/userService"
import StatisticChart from '../staistic-chart';
import { userColumns } from '../../helpers/tableColumns';
import { roleService } from '../../services/roleService';
import Select from "react-select";
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';
import "./css/users.scss";

const Users = () => {
    let dataTable = [];
    const defaultGuid = '00000000-0000-0000-0000-000000000000'
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const isAdmin = userRoleName == ROLES.ADMIN;
    const [offset, setOffset] = useState(1);
    const [datas, setData] = useState([]);
    const [countElements, setCountElements] = useState(0);
    const [isCreate, setIsCreate] = useState(false);
    const [roles, setRoles] = useState([]);

    const [search, setSearch] = useState({
        fullName: "",
        phone: "",
        role: "",
        page: 1
    });
    let itemsOnPage = 20;

    const onLoadData = async () => {
        let data = await userService.getList(search.fullName, search.phone, search.role, search.page).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);
                setData(data.items);
            }
        });
    }
    useEffect(async () => {
        if(isAdmin){
            await getRoles();
        }
    }, [])
    useEffect(async () => {
        await onLoadData();
    }, [search])

    const update = async (e) => {
        search.page = e / itemsOnPage + 1;
        await onLoadData();
    }

    const getRoles = async () => {
        let array = [];
        await roleService.getListRoles().then(async (data) => {
            if (data) {
                array.push({ value: "", label: <p style={{ margin: 18 }}></p> })
                data.items.forEach(role => {
                    array.push({ value: role.name, label: role.name })
                });
                setRoles(array);
            }
        });
    }

    const handleSearch = (event) => {
        if (event.target) {
            setSearch({
                ...search,
                [event.target.name]: event.target.value
            })
        } else {
            setSearch({
                ...search,
                ["role"]: event.value
            })
        }
        if (event.label) {
            setSearch({
                ...search,
                ["role"]: event.value === defaultGuid ? '' : event.label
            })
        }
    };

    if (datas.length != 0) {
        datas.forEach(user => {
            const obj = {
                phone: <Link to={"/user/edit/" + user.uid}>{user.phoneNumber}</Link>,
                userRef: <p className="col-12">{user.userRef ? user.userRef.refCode ? <div>⚑</div> : <div>⚐</div> : <div>⚐</div>}</p>,
                registration: getDateFull(user.createdUtcDateTime),
                LFM: <>
                    <p>{user.lastName || "-"} {user.firstName || "-"}<br />
                        {user.middleName || "-"}
                    </p>
                </>,
                birthday: getDateOnly(user.birthDate),
                country: <><i
                    className={`flag-icon flag-icon-${user.address ? user.address.countryCode : "-"} `} /> {user.address ? countries.getName(user.address.countryCode, "ru", { select: "official" }) : "-"}
                </>,
                town: user.address ? user.address.city : "-",
                address: user.address ? ((user.address.street == null ? "-" : user.address.street) + " " +
                    (user.address.building == null ? "-" + " " : user.address.building + ", ") +
                    (user.address.house == null ? "-" : user.address.house)) : "-",
                date_issue: user.document ? getDateOnly(user.document.issueDate) : "-",
                serial_num_pass: user.document ? (user.document.series + " " + user.document.number) : "- -",
                issued_by: user.document ? user.document.authority : "-"
            }
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Пользователи" />
            <div className="container-fluid">
                <div className="col-sm-6">
                    <StatisticChart num={countElements ? countElements : 0}
                        name="Кол-во пользователей" />
                </div>
                <div className="row">
                    <div className="form-group col-xl-3 col-md-6">
                        <input name="phone" placeholder="Поиск по телефону" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6">
                        <input name="fullName" placeholder="Поиск по ФИО" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    {isAdmin ? ( <div className="form-group col-xl-3 col-md-6">
                        <Select onChange={e => { handleSearch(e) }}
                            options={roles}
                            id="role"
                            placeholder="Поиск по ролям"
                            name="role" />
                    </div>) : (<></>)}
                </div>
            </div>
            <div className="container-fluid">
                <div >
                    <DataTableComponent data={dataTable} columns={userColumns} />
                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }}
                        totalArticles={datas ? countElements : 1000}
                        articlesPerPage={itemsOnPage}
                    /> : <></>
                }
            </div>
        </Fragment>
    );
};

export default Users;
