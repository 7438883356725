import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../breadcrumb';
import { Form, Badge, Row, Button, FormGroup, Label, Input, FormFeedback, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useParams } from "react-router";
import { adminService } from '../../../../services/adminService';
import { useSetState } from '../../../helpers/customHooks';
import TextField from '@material-ui/core/TextField';
import Select from "react-select";
import moment from "moment";
import { checkMessageFields } from '../../../helpers/validator';


const SystemMessageEditComponent = () => {
    const { id } = useParams();
    const types = [{ value: 0, label: "Error" }, { value: 1, label: "Info" }, { value: 2, label: "Warning" }];
    const [errorMesage, setErrorMessage] = useSetState({
        endDate: "",
        title: "",
        message: ""
    })
    const [message, setMessage] = useSetState({
        title: "",
        message: "",
        type: "",
        endDate: "",
        typeValue: ""
    });
    const onLoadData = async () => {
        await adminService.getById(id)
            .then(data => {
                setMessage({
                    title: data.title,
                    message: data.message,
                    type: data.type,
                    typeValue: getTypeValue(data.type),
                    endDate: moment(data.endDate).format('YYYY-MM-DDTHH:MM')
                })
            });
    }

    const getTypeValue = (type) => {
        if (type == "Info")
            return 1;
        if (type == "Warning")
            return 2;

        return 0;
    }

    const deleteMessage = async () => {
        await adminService.deleteMessage(id)
            .catch(error => {
                toast.error(<p>{error.message}</p>);
                return null;
            });
        toast.info(<p>{"Удалено!"}</p>);
        back();
    }

    const saveMessage = async () => {
        if (!checkMessageFields(message, errorMesage)) {
            const form = {
                id: id,
                title: message.title,
                message: message.message,
                type: message.typeValue,
                endDate: message.endDate
            }
            await adminService.edit(JSON.stringify(form))
                .catch(error => {
                    toast.error(<p>{error.message}</p>);
                });
            toast.info(<p>{"Сохранено!"}</p>);
            back();
        }
        onLoadData();
    }
    const history = useHistory();

    const back = () => {
        history.goBack();
    }



    useEffect(() => {
        onLoadData();
    }, [])

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Уведомление" />
            <div className="card">
                <div className="card-header">
                    <h5>Редактировать уведомление</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Заголовок</label>
                                {errorMesage.title != "" ?
                                    (<TextField error helperText={errorMesage.title} variant="outlined" size="small" className="form-control"
                                        onChange={e => setMessage({ title: e.target.value })} value={message.title} type="text" id="Title" />)
                                    : (<TextField variant="outlined" size="small" className="form-control"
                                        onChange={e => setMessage({ title: e.target.value })} value={message.title} type="text" id="Title" />)
                                }
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Сообщение</label>
                                {errorMesage.message != "" ?
                                    (<TextField error helperText={errorMesage.message} variant="outlined" size="small" className="form-control"
                                        onChange={e => setMessage({ message: e.target.value })} value={message.message} multiline maxRows={6} id="message" />)
                                    : (<TextField variant="outlined" size="small" className="form-control"
                                        onChange={e => setMessage({ message: e.target.value })} value={message.message} multiline maxRows={6} id="message" />)
                                }
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Тип сообщения</label>
                                <Select onChange={e => { setMessage({ type: e.label, typeValue: e.value }) }} value={{ value: message.typeValue, label: message.type }} placeholder="Тип" options={types}
                                    id="type" />
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div className="form-group">
                                <label className="form-label">Дата до</label>
                                {errorMesage.endDate != "" ? (<TextField error
                                    id="datetime-local"
                                    type="datetime-local"
                                    className="form-control"
                                    value={message.endDate}
                                    helperText={errorMesage.endDate}
                                    onChange={e => {
                                        setMessage({ endDate: e.target.value })
                                    }}
                                />) : (<TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    className="form-control"
                                    value={message.endDate}
                                    onChange={e => {
                                        setMessage({ endDate: e.target.value })
                                    }}
                                />)}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button className="btn btn-primary" onClick={e => saveMessage(e)} type="submit">Сохранить</button>
                    </div>
                    <div className="card-footer text-left">
                        <Button id="delete" onClick={e => deleteMessage(e)} outline color="info">Удалить</Button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SystemMessageEditComponent;
