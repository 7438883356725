export const country = [
    "TUR",
    "ARM",
    "KAZ",
    "AZE",
    "MDA",
    "BLR",
    "TJK",
    "KGZ",
    "RUS",
    "GEO",
    "UZB",
    "UKR"
];