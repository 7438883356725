
import { handlers } from "../helpers/hadlers"

export const adminService = {
    getList,
    create,
    deleteMessage,
    getById,
    edit
};

const apiUrl = process.env.REACT_APP_API_URL;

export function getList(pageNumber, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/system-messages?filter.pageNumber=${pageNumber}&filter.pageSize=${pageSize}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function create(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + "api/admin/system-messages/create", requestOptions)
    .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/system-messages/` + id, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteMessage(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/admin/system-messages/` + id, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function edit(message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + `api/admin/system-messages/`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
