
import { identificationService } from "../../../../services/IdentificationService"
import { toast } from "react-toastify";
import moment from "moment";

export const maxDate = moment().subtract(18, 'years').format(moment.HTML5_FMT.DATE);
export const currentDate = moment().format(moment.HTML5_FMT.DATE);

export const GetImage = async (imageUrl) => {
    let image = await identificationService.getImage(imageUrl)
    image.image = image.image.slice(1, -1);
    return image;
};

export const ToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const SendImage = async (image, docTypeId, userId) => {
    let type = 'document'
    const formData = new FormData();

    if(docTypeId === 9){
        type = 'address'
        formData.append("addressImage", image);
        formData.append("addressType", 1);
    }
    else{
        formData.append("docImage", image);
        formData.append("documentType", docTypeId);
    }
   
    formData.append("userId", userId);
    let url = '';
    await identificationService.addImage(formData, type)
        .catch(error => {
            toast.error(<p>{error.message}</p>);
        })
        .then(_ => {
            url = _;
            toast.info(<p>{"Фотография добавлена успешно!"}</p>);
        });
    return url;
}

export const SaveForm = async (docData, docTypeId, userId, cancel) => {
    const currentUser = JSON.parse(localStorage.getItem(userId));
    if(currentUser){
        docData.countryCode = currentUser.countryCode && currentUser.countryCode[0];
    }
    
    docData.documentType = docTypeId;
    docData.userId = userId;

    await identificationService.createDocument(JSON.stringify(docData))
        .catch(error => {
            toast.error(<p>{error.message}</p>);

        })
        .then(_ => {
            cancel();
            toast.info(<p>{"Документ обновлен успешно!"}</p>);
        });
}

export const SaveAddressForm = async (addressData, addressTypeId, userId, cancel) => {

    //Временая регистрация.
    addressData.countryCode = 'RUS'
    addressData.addressType = 1;
    addressData.userId = userId;
    await identificationService.createAddress(JSON.stringify(addressData))
        .catch(error => {
            toast.error(<p>{error.message}</p>);
        }).then(_ => {
            cancel();
            toast.info(<p>{"Адрес обновлен успешно!"}</p>);
        });
}