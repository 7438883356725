export const applicationIdentificationStatusHelper = (status) => {
    switch (status) {
        case "Pending":
            return "В ожидании"
        case "CourierSelected":
            return "Курьер выбран"
        case "AgreedToMeet":
            return "Встреча назначена"
        case "IdentificationFailed":
            return "Идентификация не пройдена"
        case "IdentificationPassed":
            return "Идентификация прошла успешно"
        case "Closed":
            return "Закрыта по просьбе пользователя"
    }
}
