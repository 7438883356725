import { Fragment, useEffect, useState } from "react";
import { country } from '../../../../helpers/country';
import { Link, useHistory } from "react-router-dom";
import { useSetState } from '../../../helpers/customHooks'
import { Button, Col, Row, ButtonGroup, Input, FormFeedback } from 'reactstrap';

import { Typeahead } from "react-bootstrap-typeahead";
import { getDocTypeLabel } from "../../../../helpers/docTypeHelper";
import { sex } from "../../../../helpers/sex"
import { identificationService } from "../../../../services/IdentificationService"
import { toast } from "react-toastify";
import ImageUploading from 'react-images-uploading';
import React from 'react';
import { maxDate, currentDate, GetImage, ToBase64, SendImage, SaveForm} from "./helperDocumentForm";

export const TemporaryResidencePermitForm = props => {
    const param = props;
    const oldDoc = param.oldDoc;
    const [images, setImages] = React.useState([]);
    const [notSavedImages, setNotSavedImages] = React.useState([]);
    const maxNumber = 1;
    const onChange = (imageList, addUpdateIndex) => {
        setNotSavedImages(imageList);
    };

    const addImage = async (imageFile, docTypeId, userId) => {
        const url = await SendImage(imageFile, docTypeId, userId);
        const base64 = await ToBase64(imageFile);
        setImages([...images, { imageUrl: url, image: base64 }]);
        setNotSavedImages([]);
    }

    const deleteImage = async (imageUrl) => {
        const notDeletedImages = images.filter(i => i.imageUrl !== imageUrl)
        setImages(notDeletedImages);
        await identificationService.deleteImage(imageUrl).catch(error => {
            toast.error(<p>{error.message}</p>);
        }).then(_ => {
            toast.info(<p>{"Фото удалено успешно!"}</p>);
        });
    };

    useEffect(async () => {
        if (oldDoc && oldDoc.docImages) {
            setDocData(oldDoc);
            const images = await Promise.all(oldDoc.docImages?.map(item => GetImage(item)))
            setImages(images);

            for (var key in oldDoc) {
                validate(key, oldDoc[key]);
            }
        }
    }, [oldDoc])

    const [errorMesage, setErrorMessage] = useSetState({
        number: "",
        issueDate: "",
        expiryDate: ""
    })
    const [validFields, setValidFields] = useSetState({
        number: false,
        issueDate: false,
        expiryDate: false
    })
    const isValidForm = () => {
        let isValid = true;
        for (var key in validFields) {
            if (!validFields[key]) {
                isValid = validFields[key];
                return;
            }
        }
        return isValid;
    }
    const validate = (nameField, value) => {
        if (nameField == 'number') {
            if (!value) {
                setValidFields({ number: false })
                setErrorMessage({ number: "Поле номер не должно быть пустым!" })
            }
            else if (value) {
                setValidFields({ number: true })
                setErrorMessage({ number: "" })
            }
        }
        if (nameField == 'issueDate') {
            if (!value) {
                setValidFields({ issueDate: false })
                setErrorMessage({ issueDate: "Поле дата выдачи не должно быть пустым!" })
            }
            else if (value) {
                setValidFields({ issueDate: true })
                setErrorMessage({ issueDate: "" })
            }
        }
        if (nameField == 'expiryDate') {
            if (!value) {
                setValidFields({ expiryDate: false })
                setErrorMessage({ expiryDate: "Поле дата окончания не должно быть пустым!" })
            }
            else if (value) {
                setValidFields({ expiryDate: true })
                setErrorMessage({ expiryDate: "" })
            }
        }
    }

    const [docData, setDocData] = useSetState({});
    const history = useHistory();
    const cancel = () => {
        history.goBack();
    }
    if (!oldDoc) {
        return (<>
        </>)
    }
    return (
        <>
            <div className="col-xl-8">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Тип документа</label>
                                    <input name="DocumentType" className="form-control" type="text"
                                        defaultValue={getDocTypeLabel(param.docTypeId)} disabled="true" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Номер</label>
                                    <Input invalid={errorMesage["number"] != ''} name="Number" className="form-control" type="number"
                                        defaultValue={oldDoc?.number}
                                        onChange={e => { setDocData({ number: e.target.value }); validate("number", e.target.value) }} />
                                    <FormFeedback invalid>{errorMesage["number"]}</FormFeedback>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Дата выдачи</label>
                                    <Input invalid={errorMesage["issueDate"] != ''} name="IssueDate" className="form-control" type="date"
                                        max={currentDate}
                                        defaultValue={oldDoc?.issueDate}
                                        onChange={e => { setDocData({ issueDate: e.target.value }); validate("issueDate", e.target.value) }} />
                                    <FormFeedback invalid>{errorMesage["issueDate"]}</FormFeedback>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Дата окончания</label>
                                    <Input invalid={errorMesage["expiryDate"] != ''} name="ExpiryDate" className="form-control" type="date"
                                        min={currentDate}
                                        defaultValue={oldDoc?.expiryDate}
                                        onChange={e => { setDocData({ expiryDate: e.target.value }); validate("expiryDate", e.target.value) }} />
                                    <FormFeedback invalid>{errorMesage["expiryDate"]}</FormFeedback>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    {images && images.length > 0 ? <label className="form-label">Фото</label> : <></>}
                                    {images.map((item, i) => {
                                        return (<div><img key={i} src={`${item.image}`} className="form-control" />
                                            <button className="btn btn-primary" key={i} onClick={() => deleteImage(item.imageUrl)}>Удалить
                                            </button>
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                    <ImageUploading
                                        value={notSavedImages}
                                        onChange={onChange}
                                        maxNumber={maxNumber}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }) => (
                                            // write your building UI
                                            <div className="form-control">
                                                {imageList?.length ? (<button
                                                    className="btn btn-primary"
                                                    style={isDragging ? { color: 'red' } : undefined}
                                                    onClick={() => onImageUpdate(0)}
                                                    {...dragProps}
                                                >
                                                    Изменить фотографию

                                                </button>) : (<button
                                                    className="btn btn-primary"
                                                    style={isDragging ? { color: 'red' } : undefined}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                >
                                                    Добавить фотографию

                                                </button>)}

                                                {imageList?.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <div class="d-flex justify-content-center" style={{ marginBottom: '10px', marginTop: '10px' }} >
                                                            <img src={image['data_url']} alt="" width="200" />
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <button class="btn btn-secondary" onClick={() => addImage(imageList && imageList[0] && imageList[0].file, param.docTypeId, param.userId)}>Прикрепить</button>
                                                            <button class="btn btn-danger" onClick={() => onImageRemove(index)}>Удалить</button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                            </div>
                            <Row>
                                <Col>
                                    <div className="card-footer text-left">
                                        <button className="btn btn-warn" onClick={e => cancel()}>Отмена</button>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="card-footer text-right">
                                        <button className="btn btn-primary" type="submit" disabled={!isValidForm()} onClick={e => SaveForm(docData, param.docTypeId, param.userId, cancel)}>Добавить</button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}