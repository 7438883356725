import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from "@apollo/client";
import { getDateFull, getDateOnly } from '../../../helpers/date' 
import countries from "i18n-iso-countries";
import { Link } from "react-router-dom";
import Breadcrumb from '../../breadcrumb';
import DataTableComponent from '../../dataTableComponent';
import { userService } from '../../../services/userService';
import { userColumns } from '../../../helpers/tableColumns';
import PaginationComponent from '../../pagination';
import { useParams } from "react-router";
import { getRoleName } from '../../../helpers/roleHelper';

const RoleDetail = () => {
    let dataTable = [];
    const { roleName } = useParams();
    const [offset, setOffset] = useState(1);
    const [datas, setData] = useState([]);
    const [countElements, setCountElements] = useState(0);

    const [search, setSearch] = useState({
        fullName: "",
        phone: "",
        role: "",
        page: 1
    });
    let itemsOnPage = 20;
    const onLoadData = async () => {
        search.role = roleName
        let data = await userService.getList(search.fullName, search.phone, search.role, search.page).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);
                setData(data.items);
            }
        });
    }

    useEffect(async () => {
        await onLoadData();
    }, [search])

    const update = async (e) => {
        search.page = e / itemsOnPage + 1;
        await onLoadData();
    }

    if (datas.length != 0) {
        datas.forEach(user => {
            const obj = {
                phone: <Link to={"/user/edit/" + user.uid}>{user.phoneNumber}</Link>,
                userRef: <p className="col-12">{user.userRef ? user.userRef.refCode ? <div>⚑</div> : <div>⚐</div> : <div>⚐</div>}</p>,
                registration: getDateFull(user.createdUtcDateTime),
                LFM: <>
                    <p>{user.lastName || "-"} {user.firstName || "-"}<br />
                        {user.middleName || "-"}
                    </p>
                </>,
                birthday: getDateOnly(user.birthDate),
                country: <><i
                    className={`flag-icon flag-icon-${user.address ? user.address.countryCode : "-"} `} /> {user.address ? countries.getName(user.address.countryCode, "ru", { select: "official" }) : "-"}
                </>,
                town: user.address ? user.address.city : "-",
                address: user.address ? ((user.address.street == null ? "-" : user.address.street) + " " +
                    (user.address.building == null ? "-" + " " : user.address.building + ", ") +
                    (user.address.house == null ? "-" : user.address.house)) : "-",
                date_issue: user.document ? getDateOnly(user.document.issueDate) : "-",
                serial_num_pass: user.document ? (user.document.series + " " + user.document.number) : "- -",
                issued_by: user.document ? user.document.authority : "-"
            }
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title={getRoleName(roleName)} />
            <div className="container-fluid">
                <div >
                    <DataTableComponent data={dataTable} columns={userColumns} />
                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }}
                        totalArticles={datas ? countElements : 1000}
                        articlesPerPage={itemsOnPage}
                    /> : <></>
                }
            </div>
        </Fragment>
    );
};

export default RoleDetail;