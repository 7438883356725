import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  registerables,
} from "chart.js";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

import { Card, CardContent } from "@mui/material";
import { ReactElement } from "react";

import React from "react";
Chart.register(...registerables);
export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}

export interface DataSet {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface Props {
  data: ChartData;
  chartName: string;
  children: ReactElement;
  setFrom: any;
  setTo: any;
  from: Date | null;
  to: Date | null;
}

export const BarChart = React.forwardRef(({ data, chartName }: Props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: chartName,
      },
    },
  };

  return (
    <>
      {data && (
        <Card sx={{ maxWidth: "100%", m: "1%" }}>
          <CardContent>
            <div>
              <Bar options={options} data={data} />
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
});
