export const operationColumns = [
    {
        name: <>Дата операции
        </>,
        selector: "date",
        width: "280px",

        center: true,
        compact: true,
    },
    {
        name: 'Направление',
        selector: "country",
        width: "120px",

        center: true,
        compact: true,
    },
    {
        name: <>Отправитель

        </>,
        selector: "send",
        width: "200px",

        center: true,
        compact: true,
    },
    {
        name: 'Получатель',
        selector: "get",
        width: "200px",

        center: true,
        compact: true,
    },
    {
        name: 'Валюта',
        selector: "currency",
        width: "85px",

        center: true,
        compact: true,
    },
    {
        name: 'Сумма',
        selector: "sum",
        width: "150px",

        center: true,
        compact: true,
    },
    {
        name: 'Курс',
        selector: "rate",
        width: "100px",

        center: true,
        compact: true,
    },
    {
        name: 'Комиссия',
        selector: "commission",
        width: "100px",

        center: true,
        compact: true,
    },
    {
        name: 'Доход',
        selector: "earn",
        width: "60px",

        center: true,
        compact: true,
    },
    {
        name: 'Статус',
        selector: "status",
        width: "200px",

        center: true,
        compact: true,
    }

];

export const parchaseZamColumns = [
    {
        name: <>Дата операции
        </>,
        selector: "date",
        width: "350px",

        center: true,
        compact: true,
    },
    {
        name: 'Направление',
        selector: "country",
        width: "160px",

        center: true,
        compact: true,
    },
    {
        name: <>Клент

        </>,
        selector: "send",
        width: "230px",

        center: true,
        compact: true,
    },
    {
        name: 'Валюта',
        selector: "currency",
        width: "105px",

        center: true,
        compact: true,
    },
    {
        name: 'Сумма',
        selector: "sum",
        width: "150px",

        center: true,
        compact: true,
    },
    {
        name: 'Курс',
        selector: "rate",
        width: "100px",

        center: true,
        compact: true,
    },
    {
        name: 'Комиссия',
        selector: "commission",
        width: "100px",

        center: true,
        compact: true,
    },
    {
        name: 'Доход',
        selector: "earn",
        width: "60px",

        center: true,
        compact: true,
    },
    {
        name: 'Статус',
        selector: "status",
        width: "220px",

        center: true,
        compact: true,
    }

];

export const adminColumns = [
    {
        name: 'Id',
        selector: "id",
        width: "300px",

        center: true,
        compact: true,
    },
    {
        name: 'Заголовок',
        selector: "title",
        width: "200px",

        center: true,
        compact: false,
    },
    {
        name: 'Сообщение',
        selector: "message",
        width: "650px",

        center: true,
        compact: false,
        wrap: true
    },
    {
        name: 'Тип',
        selector: "type",
        width: "100px",

        center: true,
        compact: true,
    },
    {
        name: 'Дата конца сообщения',
        selector: "endDate",
        width: "200px",

        center: true,
        compact: true,
    }
];

export const userColumns = [
    {
        name: 'Телефон',
        selector: "phone",
        width: "125px",

        center: true,
        compact: true,
    },
    {
        name: 'Партнер',
        selector: "userRef",
        width: "80px",

        center: true,
        compact: true,
    },
    {
        name: 'Дата регистрации',
        selector: "registration",
        width: "120px",

        center: true,
        compact: true,
    },
    {
        name: 'ФИО',
        selector: "LFM",
        width: "200px",

        center: true,
        compact: true,
    },
    {
        name: 'Дата рождения',
        selector: "birthday",
        width: "90px",

        center: true,
        compact: true,
    },
    {
        name: 'Страна',
        selector: "country",
        width: "120px",

        center: true,
        compact: true,
    },
    {
        name: 'Город',
        selector: "town",
        width: "100px",

        center: true,
        compact: true,
    },
    {
        name: 'Адрес',
        selector: "address",
        width: "200px",

        center: true,
        compact: true,
    },
    {
        name: 'Серия/номер паспорта',
        selector: "serial_num_pass",
        width: "150px",

        center: true,
        compact: true,
    },
    {
        name: 'Дата выдачи',
        selector: "date_issue",
        width: "90px",

        center: true,
        compact: true,
    },
    {
        name: 'Кем выдан',
        selector: "issued_by",
        width: "250px",

        center: true,
        compact: true,
    },

];

export const partnerColumns = [
    {
        name: 'Телефон',
        selector: "phone",
        width: "180px",

        center: true,
        compact: true,
    },
    {
        name: 'Дата регистрации',
        selector: "registration",
        width: "200px",

        center: true,
        compact: false,
    },
    {
        name: 'ФИО',
        selector: "LFM",
        width: "250px",

        center: true,
        compact: false,
        wrap: true
    },
    {
        name: 'Ставка за привлечение',
        selector: "pricePerInvitation",
        width: "150px",

        center: true,
        compact: true,
    },
    {
        name: 'Ставка за вовлечение',
        selector: "pricePerInvitationPay",
        width: "150px",

        center: true,
        compact: true,
    }
];

export const roleColumns = [
    {
        name: 'Id',
        selector: 'id',
        width: '300px',
        center: true,
        compact: true
    },
    {
        name: 'Имя',
        selector: 'name',
        width: '300px',
        center: true,
        compact: true
    },
    {
        name: '',
        selector: 'delete',
        width: '150px',
        center: true,
        compact: true
    }
]

export const orderColumns = [
    {
        name: 'Id заявки',
        selector: 'UniqueNumber',
        width: '100px'
    },
    {
        name: 'ФИО клиента',
        selector: 'LFM',
        width: '200px'
    },
    {
        name: 'Телефон',
        selector: 'PhoneNumber',
        width: '150px'
    },
    {
        name: 'Город',
        selector: 'City',
        width: '150px'
    },
    {
        name: 'Дата создания',
        selector: 'CreatedDateTime',
        width: '150px'
    },
    {
        name: 'Статус',
        selector: 'Status',
        width: '200px'
    },
];

export const fmrBuildingColumns = [
    {
        name: 'Id',
        selector: "id",
        width: "300px",

        center: true,
        compact: true,
    },
    {
        name: 'Код страны',
        selector: "alpha3Code",
        width: "200px",

        center: true,
        compact: false,
    },
    {
        name: 'Город',
        selector: "city",
        width: "400px",

        center: true,
        compact: false,
        wrap: true
    },
    {
        name: 'Улица',
        selector: "street",
        width: "400px",

        center: true,
        compact: true,
    },
    {
        name: 'Дом',
        selector: "building",
        width: "200px",

        center: true,
        compact: true,
    }
];

export const appIdentificationColumn = [
    {
        name: 'Id',
        selector: "id",
        width: "300px",

        center: true,
        compact: true,
    },
    {
        name: 'ФИО',
        selector: "fullName",
        width: "300px",

        center: true,
        compact: false,
    },
    {
        name: 'Номер телефона',
        selector: "phoneNumber",
        width: "200px",

        center: true,
        compact: false,
    },
    {
        name: 'Статус',
        selector: "status",
        width: "300px",

        center: true,
        compact: false,
        wrap: true
    },
    {
        name: 'Агент',
        selector: "courierId",
        width: "300px",

        center: true,
        compact: true,
    },
    {
        name: 'Время встречи',
        selector: "meetingTime",
        width: "200px",

        center: true,
        compact: true,
    }
];

export const partialIdentificationColumn = [
    {
        name: 'Id',
        selector: "id",
        width: "300px",

        center: true,
        compact: true,
    },
    {
        name: 'ФИО',
        selector: "fullName",
        width: "300px",

        center: true,
        compact: false,
    },
    {
        name: 'Номер телефона',
        selector: "phoneNumber",
        width: "200px",

        center: true,
        compact: false,
    },
    {
        name: 'Статус',
        selector: "status",
        width: "300px",

        center: true,
        compact: false,
        wrap: true
    },
];

export const courierAppIdentificationColumn = [
    {
        name: 'Id',
        selector: "id",
        width: "300px",

        center: true,
        compact: true,
    },
    {
        name: 'ФИО',
        selector: "fullName",
        width: "300px",

        center: true,
        compact: false,
    },
    {
        name: 'Номер телефона',
        selector: "phoneNumber",
        width: "200px",

        center: true,
        compact: false,
    },
    {
        name: 'Статус',
        selector: "status",
        width: "300px",

        center: true,
        compact: false,
        wrap: true
    },
    {
        name: 'Время встречи',
        selector: "meetingTime",
        width: "200px",

        center: true,
        compact: true,
    }
];
