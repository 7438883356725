const stringEmpty = "";
export function checkMessageFields(message, errorMesage) {
    if (!message.endDate)
        errorMesage.endDate = "Дата обязательна!";
    else
        errorMesage.endDate = stringEmpty;
    if (!message.title)
        errorMesage.title = "Заголовок обязателен!";
    else
        errorMesage.title = stringEmpty;
    if (!message.message)
        errorMesage.message = "Сообщение обязательно!";
    else
        errorMesage.message = stringEmpty;

    return errorMesage.endDate || errorMesage.title || errorMesage.message;
}

export function checkRoleFields(message, errorMessage) {
    if (!message.name)
        errorMessage.name = "Имя роли обазтельно";
    else
        errorMessage.name = stringEmpty;
    return errorMessage.name;
}

export function checkFmrBuildingFields(message, errorMesage) {
    if (!message.longitude)
        errorMesage.longitude = "Долгота обязательна!";
    else
        errorMesage.longitude = stringEmpty;

    if (!message.latitude)
        errorMesage.latitude = "Широта обязательна!";
    else
        errorMesage.latitude = stringEmpty;

    if (!message.alpha3Code)
        errorMesage.alpha3Code = "Код страны обязателен!";
    else
        errorMesage.alpha3Code = stringEmpty;

    if (!message.city)
        errorMesage.city = "Город обязателен!";
    else
        errorMesage.city = stringEmpty;

    if (!message.street)
        errorMesage.street = "Улица обязательна!";
    else
        errorMesage.street = stringEmpty;

    if (!message.building)
        errorMesage.building = "Дом обязателен!";
    else
        errorMesage.building = stringEmpty;

    return errorMesage.longitude || errorMesage.latitude || errorMesage.alpha3Code || errorMesage.city || errorMesage.street || errorMesage.building;
}

export function checkAppIdentificationFields(message, errorMesage) {

    if (!message.city)
        errorMesage.city = "Город обязателен!";
    else
        errorMesage.city = stringEmpty;

    if (!message.street)
        errorMesage.street = "Улица обязательна!";
    else
        errorMesage.street = stringEmpty;

    if (!message.building)
        errorMesage.building = "Дом обязателен!";
    else
        errorMesage.building = stringEmpty;

    return errorMesage.city || errorMesage.street || errorMesage.building;
}