import { handlers } from "../helpers/hadlers"

export const roleService = {
    getListRoles,
    createRole,
    deleteRole,
    setRole
};

const ssoUrl = process.env.REACT_APP_SSO_URL;

export function getListRoles(pageNumber, pageSize) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(ssoUrl + `api/admin/roles?pageNumber=${pageNumber}&pageSize=${pageSize}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function createRole(role) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: role
    };
    return fetch(ssoUrl + "api/admin/roles/create", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteRole(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(ssoUrl + `api/admin/roles/` + id, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function setRole(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(ssoUrl + "api/admin/roles/set-role", requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
