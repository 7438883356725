import { handlers } from "../helpers/hadlers";

const apiUrl = process.env.REACT_APP_API_URL;
const ssoUrl = process.env.REACT_APP_SSO_URL;

export const dashboardService = {
  getUserStatistic,
  getOperationStatistic,
  getRegisterUsersDoc,
  getActiveUsersDoc,
  getSuccessfulTransfersDoc,
  getOperationsDoc,
  getCountriesOperations
};

const httpMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
};

export function getUserStatistic(from, to) {
  return sendRequestBase(
    ssoUrl + `identity/erp/userGrowth?from=${from}&to=${to}`,
    httpMethods.get
  );
}

export function getRegisterUsersDoc(from, to) {
  return sendRequestBase(
    apiUrl + `api/admin/dashboard/count_register_users?from=${from}&to=${to}`,
    httpMethods.get
  );
}

export function getOperationsDoc(from, to, orderByStatus, orderByDate) {
  return sendRequestBase(
    apiUrl + `api/admin/dashboard/operations?from=${from}&to=${to}&orderByDate=${orderByDate}&orderByStatus=${orderByStatus}`,
    httpMethods.get
  );
}

export function getActiveUsersDoc(from, to) {
  return sendRequestBase(
    apiUrl + `api/admin/dashboard/count_active_users?from=${from}&to=${to}`,
    httpMethods.get
  );
}

export function getSuccessfulTransfersDoc(from, to) {
  return sendRequestBase(
    apiUrl +
      `api/admin/dashboard/successful_transfers_doc?from=${from}&to=${to}`,
    httpMethods.get
  );
}

export function getOperationStatistic(from, to, interval) {
  return sendRequestBase(
    apiUrl +
      `api/admin/dashboard/statistics?from=${from}&to=${to}&interval=${interval}`,
    httpMethods.get
  );
}

export function getCountriesOperations(from, to, status) {
  return sendRequestBase(
    apiUrl +
      `api/admin/dashboard/countries_operations?from=${from}&to=${to}&status=${status}`,
    httpMethods.get
  );
}

function sendRequestBase(url, method) {
  const requestOptions = {
    method: method,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
  };
  return fetch(url, requestOptions)
    .then(handlers.handleResponse)
    .then((_) => {
      return _;
    });
}

function sendRequestBaseWithBody(url, method, message) {
  const requestOptions = {
    method: method,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    body: message,
  };
  return fetch(url, requestOptions)
    .then(handlers.handleResponse)
    .then((_) => {
      return _;
    });
}
