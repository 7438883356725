import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import seven from '../../assets/images/dashboard/user.png'
import { useParams } from "react-router";
import countries from "i18n-iso-countries";
import moment from "moment";
import StatisticChart from "../staistic-chart";
import Switch from "react-switch";
import { Button, Col, Row, ButtonGroup, Input } from 'reactstrap';
import { Typeahead } from "react-bootstrap-typeahead";
import { useSetState } from '../helpers/customHooks';
import { userService } from "../../services/userService";
import { roleService } from "../../services/roleService";
import { country } from "../../helpers/country";
import { Link, useHistory } from "react-router-dom";
import ErrorAlert from '../../components/alert'
import { toast } from 'react-toastify';
import Select from "react-select";
import UserCardComponent from "../../components/users/userCardComponent";
import { guid } from 'rsuite/esm/utils';
import verifiedPic from '../../assets/images/other-images/verified.png'
import pendingPic from '../../assets/images/other-images/pending.png'
import successPic from '../../assets/images/other-images/sumsubApproval.png'

import { getDocTypeLabel } from '../../helpers/docTypeHelper';
import '../menu-pages/css/cardDoc.css'
import backgroundPlus from '../../assets/images/other-images/plus.png'
import backgroundCross from '../../assets/images/other-images/close.png'
import backgroundCheck from '../../assets/images/other-images/check.png'
import partialVerification from '../../assets/icoicon/partialVerification.png'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';
import { sumsubService } from '../../services/sumsubService';
import { getDateOnly } from '../../helpers/date';
import { getLimitByUserId, tariffService } from '../../services/tariffService';
import ProgressBar from 'react-bootstrap/ProgressBar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { partialIdentificationService } from '../../services/partialIdentificationService';

countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));
const UserEdit = () => {
    const sumsubStatus = {
        pending: 1,
        fail: 2,
        success: 3
    }
    const [userSumsubStatus, setUserSumsubStatus] = useState([]);
    const [limits, setLimits] = useState([]);

    const [documentTypes, setDocumentTypes] = useState([]);
    const [checkDocuments, setCheckDocuments] = useState([]);
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const isAdmin = userRoleName == ROLES.ADMIN;
    const isTopManager = userRoleName == ROLES.TOPMANAGER;
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER, ROLES.SUPPORT]
    const isReadOnly = !canEditRoles.includes(userRoleName);
    const isCanIdentification = userRoleName == ROLES.AGENTFMR;
    const defaultGuid = "00000000-0000-0000-0000-000000000000";
    const rusRegion = "RUS";
    const [isLoad, setIsLoad] = useState(false);
    const [userRole, setRole] = useState({
        value: defaultGuid,
        label: <p style={{ margin: 18 }}></p>
    })
    const [isExistCard, setIsExistCard] = useState(false);
    const [userEntity, setUserEntity] = useSetState({
        userUid: "",
        lastName: "",
        firstName: "",
        middleName: "",
        birthDate: "",
        passportAuthority: "",
        passportSerial: "",
        passportIssueDate: "",
        passportIssuerDepartmentCode: "",
        passportNumber: "",
        countryCode: [],
        city: "",
        address: "",
        phoneNumber: "",
        isAdvertisingPartner: false,
        roleId: "",
        clientRef: "",
        status: "",
        partialStatus: ""
    });
    const [roles, setRoles] = useState([]);
    const [errors, setErrors] = useState({});
    const { id } = useParams();
    const defaultDate = moment(userEntity.birthDate ? userEntity.birthDate : 'not time')
    const onSaveUser = async () => {
        setIsLoad(true);
        let form = userEntity;
        if (form.passportSerial == null) {
            form.passportSerial = ""
        }
        form.countryCode = Array.isArray(userEntity.countryCode) ? userEntity.countryCode[0] : userEntity.countryCode;
        form.birthDate = userEntity.birthDate && moment(userEntity.birthDate).format('DD/MM/YYYY');
        form.passportIssueDate = userEntity.passportIssueDate && moment(userEntity.passportIssueDate).format('DD/MM/YYYY');
        await userService.edit(JSON.stringify(form)).then((data) => {
            if (data && data.userInput) {
                setErrors(data.userInput);
            }
            else {
                toast.info(<p>{"Сохранено!"}</p>);
            }
        });
        await onLoad();
        setIsLoad(false);
    }

    const onLoad = async () => {
        setIsLoad(true);
        if (id && id != "edit" && id != "operation") {
            var result = await userService.getById(id).then(_ => {
                setUserEntity({
                    userUid: id,
                    lastName: _.lastName,
                    firstName: _.firstName,
                    middleName: _.middleName,
                    birthDate: _.birthDateTime && dateConvert(_.birthDateTime, '.'),
                    passportAuthority: _.passportAuthority,
                    passportSerial: _.passportSerial,
                    passportIssueDate: _.passportIssueDateTime && dateConvert(_.passportIssueDateTime, '.'),
                    passportIssuerDepartmentCode: _.passportIssuerDepartmentCode,
                    passportNumber: _.passportNumber,
                    countryCode: [_.countryCode],
                    city: _.city,
                    address: _.address,
                    clientRef: _.clientRef,
                    phoneNumber: _.phoneNumber,
                    isAdvertisingPartner: _.isAdvertisingPartner,
                    roleId: _.roleId,
                    status: _.identificationStatus,
                    partialStatus: _.partialIdentificationStatus
                });
            }).then(async () => {
                await getRoles();
                await checkAndSetUserRole();
            })
            setIsLoad(false)
        }
    }
    useEffect(async () => {
        await onLoad();
        if (id && id != "edit" && id != "operation") {
            getStatusSumsub();
            await getDocuments();
            await getLimits();
        }
    }, [id])

    const dateConvert = (date, separator) => {
        date = date.split("T")[0];
        const split = date.split(separator);
        if (split.length > 1) {
            return split[2] + "-" + split[1] + "-" + split[0];
        }
        return date;
    }
    const getLimits = async () => {
        await tariffService.getLimitByUserId(id).then(async (data) => {
            if (data) {
                let limit = [];
                data.forEach(element => {
                    let startPeriod = getDateOnly(element.startPeriod);
                    let endPeriod = getDateOnly(element.endPeriod);
                    limit.push({
                        startPeriod: startPeriod,
                        endPeriod: endPeriod,
                        totalAmount: element.totalAmount,
                        periodName: element.periodName,
                        howMuchSpentAmount: element.howMuchSpentAmount,
                    })
                });
                setLimits(limit);
            }
        });
    }

    const getRoles = async () => {
        if (!isAdmin) {
            return;
        }
        let array = [];
        await roleService.getListRoles().then(async (data) => {
            if (data) {
                array.push({ value: defaultGuid, label: <p style={{ margin: 18 }}></p> })
                data.items.forEach(role => {
                    array.push({ value: role.id, label: role.name })
                });
                if (isTopManager)
                    array.filter(function (role) {
                        return role != ROLES.ADMIN
                    });

                setRoles(array);
            }
        });
        checkAndSetUserRole();
    }

    const checkAndSetUserRole = async () => {
        await roles.forEach(role => {
            if (role.value == userEntity.roleId) {
                setRole({
                    value: role.value,
                    label: role.label
                })
            }
        });
    }
    const checkDocFields = (element, docTypeId) => {
        if (docTypeId == 0) {
            if (element.number && element.issueDate && element.series && element.authority && element.countryOfResidence && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 1) {
            if (element.number && element.issueDate && element.series && element.authority && element.countryOfResidence && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 2) {
            if (element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 3) {
            if (element.number && element.issueDate && element.expiryDate && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 4) {
            if (element.number && element.issueDate && element.expiryDate && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 5) {
            if (element.number && element.issueDate && element.expiryDate && element.series && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 8) {
            if (element.number && element.issueDate && element.expiryDate && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 9) {
            if (element.city && element.countryCode && element.street && element.building)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 10) {
            if (element.number && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 11) {
            if (element.number && element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        if (docTypeId == 12) {
            if (element.docImages && element.docImages.length > 0)
                return 2;
            else
                return 1;
        }
        return 1;
    }
    const clickOnCard = async (docTypeId) => {
        history.push(`/document/${id}/${docTypeId}`);
    }
    async function getDocuments() {
        let documents = [];
        let statusDocuments = [];
        await userService.getAddressesById(id, 1).then(_ => {
            let address = _ && _.addresses && _.addresses[0];
            if (address) {
                documents.push({ value: 9, label: getDocTypeLabel(9) });
                // 9 - временая регистрация
                statusDocuments[9] = checkDocFields(address, 9);
            }

        })
        await userService.getDocumentsById(id, null).then(_ => {
            _.documents.forEach(element => {
                documents.push({ value: element.documentType, label: getDocTypeLabel(element.documentType) });
                statusDocuments[element.documentType] = checkDocFields(element, element.documentType);
            });
            setDocumentTypes(documents);
            setCheckDocuments(statusDocuments);
            setIsLoad(false)
        })
    }

    const onChangeRole = async (roleId) => {
        roleService.setRole(JSON.stringify({ roleId: roleId, userId: userEntity.userUid })).then(async (data) => {
            if (data && data.appIdentificationInput) {
                setErrors(data.appIdentificationInput);
            }
            else {
                toast.info(<p>{"Роль успешна изменена!"}</p>);
            }
        });
    }

    useEffect(async () => {
        await checkAndSetUserRole();
    }, [roles])

    const history = useHistory();

    const onIdentification = () => {
        history.push("/identification/" + id);
    }
    const restartIdentification = async (e) => {
        e.preventDefault();

        const form = {
            userId: id,
            partialIdentificationStatus: 0
        }
        await partialIdentificationService.updateUserPartialIdentificationStatus(JSON.stringify(form)).then(async (data)=>{
            toast.info(<p>{"Частичная идентификация сброшена!"}</p>);
        })
    }

    const sendSumsub = async (e) => {
        e.preventDefault();
        const obj = {
            userId: id
        }
        await sumsubService.sendSumsub(JSON.stringify(obj)).then(async (data) => {
            if (data) {
                setErrors(data);
            }
            else {
                toast.info(<p>{"Успешно отправлено!"}</p>);
            }
        });
    }

    const getStatusSumsub = () => {
        sumsubService.getStatus(id).then(async (data) => {
            if (data && data.kycVerificationStatus != null && data.kycVerificationStatus != undefined) {
                setUserSumsubStatus(data.kycVerificationStatus + 1);
            }
        });
    }

    const operation = () => {
        history.push("/user/operation/" + id);
    }
    if (isLoad || roles.count == 0 || !limits) {
        return (<React.Fragment >
        </React.Fragment >)
    }
    return (
        <React.Fragment >
            <Breadcrumb parent="Пользователь" title="Редактировать профиль" />
            {errors == undefined ? <></> : <ErrorAlert data={errors} setErrors={setErrors} />}
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Профиль</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript"
                                            data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a><a
                                                className="card-options-remove" href="javascript" data-toggle="card-remove"><i
                                                className="fe fe-x" />
                                        </a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <div className='flex'>
                                                    <h3 className="mb-1">{userEntity.lastName || ' '} {userEntity.firstName || ' '}</h3>
                                                    {userEntity.partialStatus == 2
                                                        ? (<img src={partialVerification} style={{ width: "30px", height: "30px", marginLeft: "10px" }} alt="verified"></img>)
                                                        : (<></>)}
                                                    {userEntity.status == 2
                                                        ? (<img src={verifiedPic} style={{ width: "30px", height: "30px", marginLeft: "10px" }} alt="verified"></img>)
                                                        : (<></>)}
                                                    {userSumsubStatus === sumsubStatus.pending
                                                        ? (<img src={pendingPic} style={{ width: "30px", height: "30px", marginLeft: "10px" }} alt="verified"></img>)
                                                        : (<></>)}
                                                    {userSumsubStatus === sumsubStatus.success
                                                        ? (<img src={successPic} style={{ width: "30px", height: "30px", marginLeft: "10px" }} alt="verified"></img>)
                                                        : (<></>)}
                                                </div>
                                            </div>
                                            {userEntity.partialStatus !== 3

                                                ? (<></>)
                                                : (<div className='col'><button className="btn btn-success" onClick={event => restartIdentification(event)} type="submit">Повторить частичную идентификацию</button>  </div>)}
                                            {userEntity.status == 2 || (!isCanIdentification)

                                                ? (<></>)
                                                : (<div className='col'><button className="btn btn-success" onClick={event => onIdentification()} type="submit">Провести идентификацию</button>  </div>)}
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">Телефон</label>
                                            <input className="form-control" defaultValue={userEntity.phoneNumber || ' '}
                                                placeholder="your-email@domain.com" readOnly />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Пароль</label>
                                            <input className="form-control" type="password" defaultValue="password"
                                                readOnly />
                                        </div>
                                        {userEntity.status !== 2 || userSumsubStatus === sumsubStatus.success || userSumsubStatus === sumsubStatus.pending
                                            ? (<></>)

                                            : (<div className='col'><button className="btn btn-success" onClick={event => sendSumsub(event)} type="submit">Отправить на проверку в SumSub</button> </div>)}



                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Лимиты</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript"
                                            data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a><a
                                                className="card-options-remove" href="javascript" data-toggle="card-remove"><i
                                                className="fe fe-x" />
                                        </a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group">
                                            {limits.map((element, key) => {
                                                return (

                                                    <div style={{ marginBottom: '10px' }}>
                                                        <label key={key} className="form-label">{element.periodName}</label>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                            <div style={{ backgroundColor: "Olive", fontColor: "white", padding: "3px", borderRadius: "8px" }}>
                                                                <p style={{ color: "white" }}>
                                                                    {element.startPeriod}
                                                                </p>

                                                            </div>
                                                            <div style={{ backgroundColor: "Olive", fontColor: "white", padding: "3px", borderRadius: "8px" }}>
                                                                <p style={{ color: "white" }}>
                                                                    {element.endPeriod}
                                                                </p>

                                                            </div>
                                                        </div>

                                                        <ProgressBar now={element.howMuchSpentAmount} max={element.totalAmount} label={element.howMuchSpentAmount + " руб."} />
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "7px" }}>
                                                            <div style={{ backgroundColor: "gray", padding: "3px", borderRadius: "8px" }}>
                                                                <p style={{ textAlign: "center", color: "white" }}>
                                                                    {element.howMuchSpentAmount + " руб. / " + element.totalAmount + " руб."}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </div>)
                                            })}
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Фамилия</label>
                                                <input name="LastName" className="form-control" type="text"
                                                    onChange={e => setUserEntity({ lastName: e.target.value })}
                                                    readOnly={isReadOnly}
                                                    defaultValue={userEntity.lastName} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Имя</label>
                                                <input name="FirstName" className="form-control" type="text"
                                                    onChange={e => setUserEntity({ firstName: e.target.value })}
                                                    readOnly={isReadOnly}
                                                    defaultValue={userEntity.firstName} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Отчество</label>
                                                <input name="MiddleName" className="form-control" type="text"
                                                    onChange={e => setUserEntity({ middleName: e.target.value })}
                                                    readOnly={isReadOnly}
                                                    defaultValue={userEntity.middleName} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Дата рождения</label>
                                                <input type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }}
                                                    onChange={e => {
                                                        setUserEntity({ birthDate: moment(e.target.value).format("MM/DD/YYYY") })
                                                    }}
                                                    className="form-control"
                                                    readOnly={isReadOnly}
                                                    placeholder="дд.мм.гггг"
                                                    value={defaultDate._isValid ? moment(userEntity.birthDate).format('YYYY-MM-DD') : null}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">

                                                <label className="form-label">Страна</label>
                                                {isReadOnly ?
                                                    (<input name="Country" className="form-control" type="text" value={userEntity.countryCode}
                                                        readOnly={true} />)
                                                    :
                                                    (<Typeahead
                                                        id="selectedResponsible"
                                                        labelKey={option => `${option}`}
                                                        options={country}
                                                        placeholder="Выберите"
                                                        defaultSelected={userEntity.countryCode !== null ? [userEntity.countryCode] : []}
                                                        onChange={e => {
                                                            setUserEntity({ countryCode: e })
                                                        }}
                                                    />)
                                                }

                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Город</label>
                                                <input name="City" className="form-control" type="text"
                                                    defaultValue={userEntity.city}
                                                    readOnly={isReadOnly}
                                                    onChange={e => setUserEntity({ city: e.target.value })} />
                                            </div>
                                        </div>
                                        {userEntity.countryCode[0] === rusRegion && <div className="col-xl-3 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Серия</label>
                                                <input name="Number" className="form-control" type="text"
                                                    defaultValue={userEntity.passportSerial}
                                                    readOnly={isReadOnly}
                                                    onChange={e => setUserEntity({ passportSerial: e.target.value })} />
                                            </div>
                                        </div>}
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">{userEntity.countryCode[0] === rusRegion ? 'Номер' : 'Паспортные данные'}</label>
                                                <input name="Number" className="form-control" type="text"
                                                    defaultValue={userEntity.passportNumber}
                                                    readOnly={isReadOnly}
                                                    onChange={e => setUserEntity({ passportNumber: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Дата выдачи</label>
                                                <input type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }}
                                                    onChange={e => {
                                                        setUserEntity({ passportIssueDate: moment(e.target.value).format("MM/DD/YYYY") })
                                                    }}
                                                    className="form-control"
                                                    placeholder="дд.мм.гггг"
                                                    readOnly={isReadOnly}
                                                    value={defaultDate._isValid ? moment(userEntity.passportIssueDate).format('YYYY-MM-DD') : null}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Код подразделения</label>
                                                <input
                                                    defaultValue={userEntity.passportIssuerDepartmentCode}
                                                    onChange={e => setUserEntity({ passportIssuerDepartmentCode: e.target.value })}
                                                    readOnly={isReadOnly}
                                                    name="DepartmentCode" className="form-control" type="text" placeholder="ZIP Code" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group">
                                                <label className="form-label">Кем выдан</label>
                                                <input name="Authority" className="form-control" type="text"
                                                    defaultValue={userEntity.passportAuthority}
                                                    readOnly={isReadOnly}
                                                    onChange={e => setUserEntity({ passportAuthority: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group">
                                                <label className="form-label">Роль</label>
                                                {!isTopManager && !isAdmin ?
                                                    (<input name="Role" className="form-control" type="text" value={userRole.value != defaultGuid ? userRole.label : ""}
                                                        readOnly={true} />)
                                                    :
                                                    (<Select onChange={e => { onChangeRole(e.value) }}
                                                        options={roles}
                                                        id="role"
                                                        defaultValue={{ label: userRole.label, value: userRole.value }}
                                                        name="role" />)
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isReadOnly ?
                                    <Row>
                                        <Col>
                                            <div className="card-footer text-left">
                                                <button className="btn btn-warn" onClick={e => operation()}>Операции</button>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="card-footer text-right">
                                                <button className="btn btn-primary" onClick={event => onSaveUser()} type="submit">Сохранить</button>
                                            </div>
                                        </Col>
                                    </Row>
                                    : <></>
                                }

                            </div>
                        </div>
                    </div>
                    {documentTypes != null && documentTypes.length > 0 ? (
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap bd-highlight example-parent">
                                        {documentTypes.map((item, index) => {
                                            return (
                                                <div key={index + 20} className="p-2 bd-highlight col-example" >
                                                    <Card onClick={e => clickOnCard(item.value)} >
                                                        <CardActionArea>
                                                            <CardContent sx={{ padding: "0px" }}>
                                                                <div className="card-doc" >
                                                                    <div className="card-details-doc" style={{ backgroundImage: `url(${checkDocuments[item.value] == 1 ? backgroundCross : (checkDocuments[item.value] == 2 ? backgroundCheck : backgroundPlus)})` }}>
                                                                        <h3>{item.label}</h3>
                                                                        <div className="card-line" />
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>) : <></>}
                </div>
            </div>
        </React.Fragment >
    );
};



export default UserEdit;


