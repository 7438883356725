import { Redirect } from 'react-router';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';

const ProtectedRoute = ({
    allowedRoles = [],
    children,
}) => {
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);

    if (decoded.role == ROLES.ADMIN || decoded.role == ROLES.TOPMANAGER || (allowedRoles && allowedRoles.includes(decoded.role))) {
        return children;
    }

    return <Redirect to='/login' />;
};

export default ProtectedRoute;