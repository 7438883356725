import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import PaginationComponent from "../pagination";
import { useQuery } from "@apollo/client";
import { getDateFull, getDateOnly } from "../../helpers/date";
import countries from "i18n-iso-countries";
import { Link } from "react-router-dom";
import DataTableComponent from "../dataTableComponent";
import Loader from "../loader";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import StatisticChart from '../staistic-chart';
import { fmrBuildingColumns } from '../../helpers/tableColumns';
import { identificationService } from '../../services/IdentificationService';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';

const FrmBuildings = () => {
    let dataTable = [];
    const [offset, setOffset] = useState(1);
    const [datas, setData] = useState([]);
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER]
    const isReadOnly = !canEditRoles.includes(userRoleName);
    const [isCreate, setIsCreate] = useState(false);

    const onLoadData = async () => {
        await identificationService.getFmrs().then((data) => {
            if (data) {
                setData(data);
            }
        });
    }
    useEffect(async () => {
        await onLoadData();
    }, [])

    const onCreate = () => {
        window.location.href = `${process.env.PUBLIC_URL}/fmrBuilding/create`;
    }

    if (datas.length != 0) {
        datas.forEach(fmrBuilding => {
            const obj = {
                id: isReadOnly ? fmrBuilding.id : <Link to={"/fmrBuilding/edit/" + fmrBuilding.id}>{fmrBuilding.id}</Link>,
                alpha3Code: fmrBuilding.alpha3Code ? fmrBuilding.alpha3Code : "-",
                city: fmrBuilding.city ? fmrBuilding.city : "-",
                street: fmrBuilding.street ? fmrBuilding.street : "-",
                building: fmrBuilding.building ? fmrBuilding.building : "-",

            }
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            {!isReadOnly &&
                <div className="container-fluid">
                    <Breadcrumb parent="Home" title="Фмр отделения" />
                    <div className="form-group text-right">
                        <button className="btn btn-primary" onClick={event => onCreate()} type="submit">Добавить</button>
                    </div>
                </div>
            }

            <div className="container-fluid">
                <div >
                    <DataTableComponent data={dataTable} columns={fmrBuildingColumns} />
                </div>
            </div>
        </Fragment>
    );
};

export default FrmBuildings;