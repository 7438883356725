import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Col, Row, ButtonGroup, Input, Breadcrumb } from 'reactstrap';
import { identificationService } from '../../../services/IdentificationService';
import { useSetState } from '../../helpers/customHooks';
import { Typeahead } from "react-bootstrap-typeahead";
import { country } from '../../../helpers/country';
import { checkFmrBuildingFields } from '../../helpers/validator';
import TextField from '@material-ui/core/TextField';

const FmrCreate = () => {

    const [errorMesage, setErrorMessage] = useSetState({
        longitude: "",
        latitude: "",
        alpha3Code: "",
        city: "",
        street: "",
        building: "",
        check: ""
    })

    const [fmrEntity, setFmrEntity] = useSetState({
        latitude: "",
        longitude: "",
        alpha3Code: "",
        city: "",
        street: "",
        building: "",
        frame: "",
        postalCode: "",
    });

    const createFmr = async (e) => {
        if (!checkFmrBuildingFields(fmrEntity, errorMesage)) {
            fmrEntity.alpha3Code = Array.isArray(fmrEntity.alpha3Code) ? fmrEntity.alpha3Code[0] : fmrEntity.alpha3Code;
            await identificationService.createFmr(JSON.stringify(fmrEntity))
                .catch(error => {
                    toast.error(<p>{error.message}</p>);
                });
            toast.info(<p>Отдел ФМР добавлен!</p>)
            window.location.href = `${process.env.PUBLIC_URL}/fmrBuildings`;
        }
        setErrorMessage(errorMesage);
    }

    const onCancel = () => {
        window.location.href = `${process.env.PUBLIC_URL}/fmrBuildings`;
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Добавить новое отделение ФМР" />
            <div className="col-xl-8">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Долгота</label>
                                    {errorMesage.longitude != "" ?
                                        (<TextField error helperText={errorMesage.longitude} variant="outlined" size="small" className="form-control"
                                            onChange={e => { setFmrEntity({ longitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.longitude} type="number" id="Longitude" />)
                                        : (<TextField variant="outlined" size="small" className="form-control"
                                            onChange={e => { setFmrEntity({ longitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.longitude} type="number" id="Longitude" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Широта</label>
                                    {errorMesage.latitude != "" ?
                                        (<TextField error helperText={errorMesage.latitude} variant="outlined" size="small" className="form-control"
                                            onChange={e => { setFmrEntity({ latitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.latitude} type="number" id="Latitude" />)
                                        : (<TextField variant="outlined" size="small" className="form-control"
                                            onChange={e => { setFmrEntity({ latitude: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.latitude} type="number" id="Latitude" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Код страны в 3 символа</label>
                                    <Typeahead
                                        id="selectedResponsible"
                                        isInvalid={errorMesage.alpha3Code != ""}
                                        labelKey={option => `${option}`}
                                        options={country}
                                        placeholder="Выберите"
                                        onChange={e => {
                                            setFmrEntity({ alpha3Code: e })
                                        }}
                                    />
                                    {errorMesage.alpha3Code != "" ? <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense" id="Street-helper-text">{errorMesage.alpha3Code}</p> : <></>}
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Город</label>
                                    {errorMesage.city != "" ?
                                        (<TextField error helperText={errorMesage.city} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                            onChange={e => { setFmrEntity({ city: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.city} type="text" id="City" />)
                                        : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                            onChange={e => { setFmrEntity({ city: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.city} type="text" id="City" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Улица</label>
                                    {errorMesage.street != "" ?
                                        (<TextField error helperText={errorMesage.street} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                            onChange={e => { setFmrEntity({ street: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.street} type="text" id="Street" />)
                                        : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 30 }}
                                            onChange={e => { setFmrEntity({ street: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.street} type="text" id="Street" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-3">
                                <div className="form-group">
                                    <label className="form-label">Дом</label>
                                    {errorMesage.building != "" ?
                                        (<TextField error helperText={errorMesage.building} variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 5 }}
                                            onChange={e => { setFmrEntity({ building: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.building} type="text" id="Building" />)
                                        : (<TextField variant="outlined" size="small" className="form-control" inputProps={{ maxLength: 5 }}
                                            onChange={e => { setFmrEntity({ building: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} value={fmrEntity.building} type="text" id="Building" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label">Корпус</label>
                                    <input name="Frame" className="form-control" type="text"
                                        onChange={e => { setFmrEntity({ frame: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <label className="form-label">Индекс</label>
                                    <input name="PostalCode" className="form-control" type="text"
                                        onChange={e => { setFmrEntity({ postalCode: e.target.value }); checkFmrBuildingFields(fmrEntity, errorMesage) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <div className="card-footer text-left">
                                <button className="btn btn-warn" onClick={e => onCancel()}>Отмена</button>
                            </div>
                        </Col>
                        <Col>
                            <div className="card-footer text-right">
                                <button className="btn btn-primary" onClick={event => createFmr()} type="submit">Добавить</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment>
    );
};

export default FmrCreate;