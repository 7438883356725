import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import Country from "../country";
import Select from "react-select";
import OperationsTable from "../operationsTable";
import { countryService } from '../../services/countryService';
import { Loader } from 'react-feather';
import { operationStatuses, operationTypes, contractors } from '../../helpers/statuses'
import "./css/operations.scss"
const Operations = () => {
    const options = [];
    const [isLoad, setLoad] = useState(true);
    const [search, setSearch] = useState({
        country: "",
        operationStatus: 0,
        senderId: "",
        sender: "",
        recepient: "",
        search: "",
        startDate: "",
        endDate: "",
        source: "",
        uid: "",
        page: 1,
        operationType: 0,
        contractor: ""
    });

    const onLoadData = async () => {
        let data = await countryService.getList().then((data) => {
            if (data) {
                options.push({ value: "", label: <p style={{ fontSize: 20, margin: 0, padding: 0 }}>Все</p> })
                data.items.map(country => {
                    options.push({ value: country.country3Code, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
            }
            setLoad(false);
        });
    }

    useEffect(async () => {
        await onLoadData();
    }, [options])

    const handleSearch = (event) => {
        if (event.target) {
            setSearch({
                ...search,
                [event.target.name]: event.target.value
            })
        } else {
            if ((typeof event.value) === "number") {
                setSearch({
                    ...search,
                    ["operationStatus"]: event.value
                })
            } else {
                setSearch({
                    ...search,
                    ["country"]: event.value
                })
            }
        }
    };

    const handleContractor = (event) => {
        setSearch({
            ...search,
            ["contractor"]: event.value
        })
    }

    const handleOperationType = (event) => {
        setSearch({
            ...search,
            ["operationType"]: event.value
        })
    }

    if (isLoad) {
        return <Loader />
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Переводы" />

            <OperationsTable search={search}>
                <div className="row">
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <input name="uid" placeholder="По Uid перевода" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <input name="search" placeholder="По номеру перевода Юнистрим и ZamZam" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <Select onChange={handleSearch} placeholder="Поиск по стране" options={options} name="country" />
                    </div>


                    <div className="form-group col-xl-3 col-md-6 filter">
                        <input name="sender" placeholder="По ФИО и номеру телефона отправителя" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <input name="recepient" placeholder="По ФИО и номеру телефона получателя" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <Select onChange={handleSearch} placeholder="Поиск по статусу" options={operationStatuses}
                            name="operationStatus" />
                    </div>
                    <div className="form-group col-xl-3 col-md-3 filter">
                        <input name="startDate" type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }}
                            onChange={event => handleSearch(event)}
                            className="form-control"
                            placeholder="Дата от"
                        />

                    </div>
                    <div className="form-group col-xl-3 col-md-3 filter">
                        <input name="endDate" type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }}
                            onChange={event => handleSearch(event)}
                            className="form-control"
                            placeholder="Дата до"
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <Select onChange={handleOperationType} placeholder="Поиск по типу перевода" options={operationTypes}
                            name="operationType" />
                    </div>
                    <div className="form-group col-xl-3 col-md-6 filter">
                        <Select onChange={handleContractor} placeholder="Поиск по контрагенту" options={contractors}
                            name="contractor" />
                    </div>

                </div>

            </OperationsTable>
        </Fragment>
    );
};

export default Operations;