import { handlers } from "../helpers/hadlers"

export const partnerService = {
    getList,
    getChart
};

const apiUrl = process.env.REACT_APP_API_URL;

export function getList() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/partners/`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
export function getChart(source) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + `api/partners/get_growth_of_partners_users/?source=` + source, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
