import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import Breadcrumb from '../breadcrumb';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';
import './css/admin.css'
import limitIcon from '../../assets/icoicon/limitSetting.png'
import systemMessageIcon from '../../assets/icoicon/systemMessageSetting.png'
import {useHistory} from 'react-router-dom';


const Admin = () => {
    const history = useHistory();
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER]
    const isReadOnly = !canEditRoles.includes(userRoleName);

    const onSystemMessage = async () => {
        history.push(`${process.env.PUBLIC_URL}/admin/message`)
    }
    const onLimit = async () => {
        history.push(`${process.env.PUBLIC_URL}/login`)
    }
    
    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Администрирование" />
            {!isReadOnly &&
                <div>
                    <div className='listSettings'>
                        <div className="row justify-content-center" >
                            <div className="col-xl-3 col-md-3 mt-5">
                                <button className='setting' onClick={onSystemMessage} disabled={false}>
                                    <img src={systemMessageIcon} style={{ height: "24px", width: "24px" }} />
                                    <h5 style={{ textAlignVertical: "center", color: "white" }}>Системные сообщения</h5>
                                </button>
                            </div>
                            <div className="col-xl-3 col-md-3 mt-5">
                                <button className='setting' onClick={onLimit} disabled={false}>
                                    <img src={limitIcon} style={{ height: "24px", width: "24px" }} />

                                    <h5 style={{ textAlignVertical: "center", color: "white" }}>Настрйока лимитов</h5>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>}
        </Fragment>
    );
};

export default Admin;
