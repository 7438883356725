import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import PaginationComponent from "../pagination";
import { useQuery } from "@apollo/client";
import { getDateFull, getDateOnly } from "../../helpers/date";
import countries from "i18n-iso-countries";
import { Link } from "react-router-dom";
import DataTableComponent from "../dataTableComponent";
import Loader from "../loader";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import StatisticChart from '../staistic-chart';
import { partialIdentificationColumn } from '../../helpers/tableColumns';
import { partialIdentificationService } from '../../services/partialIdentificationService';
import {partialIdentificationStatuses} from '../../helpers/statuses';
import Select from "react-select";
import { authService } from '../../services/authService';
import { partialIdentificationStatusHelper } from '../../helpers/partialIdentificationStatusHelper';
import jwt_decode from "jwt-decode";
import moment from 'moment';
import { ROLES } from '../../helpers/roleHelper';

const PartialIdentifications = () => {
    let dataTable = [];
    const [offset, setOffset] = useState(1);
    const [datas, setData] = useState([]);
    const [countElements, setCountElements] = useState(0);
    const [isAgent, setIsAgent] = useState(false);
    const [search, setSearch] = useState({
        page: 1,
        name: "",
        phoneNumber: "",
        status: 0,
        courierId: ""
    });
    const itemsOnPage = 20;

    const onLoadData = async () => {
        let isAgentLocal = false;
        const token = localStorage.getItem("token");
        const roleName = jwt_decode(token).role;
        if (roleName == ROLES.IDENTIFICATIONAGENT) {
            isAgentLocal = true;
            setIsAgent(true);
        }
        await partialIdentificationService.getPartialIdentifications(search.page, search.name, search.phoneNumber, search.status).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);
                setData(data.items);
            }
        });
    }

    useEffect(async () => {
        await onLoadData();
    }, [search])

    const update = async (e) => {
        search.page = e / itemsOnPage + 1;
        await onLoadData();
    }

    const handleSearch = (event) => {
        if (event.target) {
            setSearch({
                ...search,
                [event.target.name]: event.target.value
            })
        }
        else {
            if ((typeof event.value) === "number") {
                setSearch({
                    ...search,
                    ["status"]: event.value
                })
            }
        }
    };

    if (datas.length != 0) {
        datas.forEach(appIdentification => {
            const obj = {
                id: <Link to={"/partialIdentification/edit/" + appIdentification.id}>{appIdentification.id}</Link>,
                fullName: <p>{appIdentification.lastName || "-"} {appIdentification.firstName || "-"} {appIdentification.middleName || "-"}</p>,
                phoneNumber: appIdentification.phoneNumber || "-",
                status: appIdentification.status ? partialIdentificationStatusHelper(appIdentification.status) : "-"
            }
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Заявки" />
            <div className="container-fluid">
                <div className="row">
                    <div className="form-group col-xl-3 col-md-6">
                        <input name="name" placeholder="Поиск по ФИО" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6">
                        <input name="phoneNumber" placeholder="Поиск по телефону" className="form-control" type="text"
                            onChange={event => handleSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6">
                        <Select onChange={handleSearch} placeholder="Поиск по статусу" options={partialIdentificationStatuses}
                            name="status" />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div >
                    <DataTableComponent data={dataTable} columns={partialIdentificationColumn} />
                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }}
                        totalArticles={datas ? countElements : 1000}
                        articlesPerPage={itemsOnPage}
                    /> : <></>
                }
            </div>
        </Fragment>
    );
};

export default PartialIdentifications;