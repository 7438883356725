import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../breadcrumb';
import PaginationComponent from "../pagination";
import DataTableComponent from "../dataTableComponent";
import { ReactComponent as Trash } from "../../assets/images/other-images/trash.svg";
import { toast } from "react-toastify";
import { useSetState } from '../helpers/customHooks';
import TextField from '@material-ui/core/TextField';
import { checkRoleFields } from '../helpers/validator';
import { roleColumns } from '../../helpers/tableColumns';
import { roleService } from '../../services/roleService';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../helpers/roleHelper';

const Roles = () => {
    const pageSize = 5;
    const [offset, setOffset] = useState(1);
    const [isSuccess, setIsSuccess] = useState(false);
    const [datas, setData] = useState([]);
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER]
    const isReadOnly = !canEditRoles.includes(userRoleName);
    const [countElements, setCountElements] = useState(0);
    const [isCreate, setIsCreate] = useState(false);
    const [errorMesage, setErrorMessage] = useState({
        name: ""
    });
    const [role, setRole] = useState({
        name: ""
    });
    const [search, setSearch] = useState({
        pageNumber: 1
    });
    let dataTable = [];

    const onLoadData = async () => {
        let data = await roleService.getListRoles(search.pageNumber, pageSize).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);
                setData(data.items);
            }
        });
    }

    useEffect(async () => {
        setIsSuccess(true);
        await onLoadData();
        setIsSuccess(false);

    }, [search])

    const update = async (e) => {
        search.pageNumber = e / pageSize + 1;
        await onLoadData();
    }

    const createRole = async (e) => {
        if (!checkRoleFields(role, errorMesage)) {
            setIsCreate(!isCreate);
            setIsSuccess(true);
            e.preventDefault();
            let result = await roleService.createRole(JSON.stringify(role))
                .catch(error => {
                    toast.error(<p>{error}</p>);
                });
            toast.info(<p>Уведомление добавлено!</p>)
            setIsSuccess(false);
        }
        await update();
    }

    const deleteRole = async (id) => {
        let result = await roleService.deleteRole(id)
            .then(_ => {
                toast.info("Удалено!");
            })
            .catch(error => {
                toast.error(<p>{error}</p>);
                return;
            });
        await update();
    }

    if (datas.length != 0) {
        datas.forEach(data => {
            const obj = {
                id: <Link to={"/role/" + data.name}>{data.id}</Link>,
                name: data.name,
                delete: isReadOnly ? <></> : <button onClick={e => deleteRole(data.id)} class="btn btn-light"><Trash /></button>
            };
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Роли" />
            {!isReadOnly &&
                <div className="card">
                    <div className="card-header">
                        <h5>Добавить новую роль</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Имя</label>
                                    {errorMesage.name != "" ?
                                        (<TextField error helperText={errorMesage.name} variant="outlined" size="small" className="form-control"
                                            onChange={e => setRole({ name: e.target.value })} defaultValue={role.title} type="text" id="Name" />)
                                        : (<TextField variant="outlined" size="small" className="form-control"
                                            onChange={e => setRole({ name: e.target.value })} defaultValue={role.title} type="text" id="Name" />)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <button className="btn btn-primary" onClick={e => createRole(e)} type="submit">Добавить</button>
                        </div>
                    </div>
                </div>
            }

            <div className="container-fluid">
                <div className="row">

                    <DataTableComponent data={dataTable} columns={roleColumns} />

                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }}
                        totalArticles={datas ? countElements : 1000}
                        articlesPerPage={pageSize}
                    /> : <></>
                }
            </div>
        </Fragment>
    );
};

export default Roles;