
export const partialIdentificationStatus = [
    { value: 1, label: "В ожидании" },
    { value: 2, label: "Идентификация не пройдена" },
    { value: 3, label: "Идентификация прошла успешно" },
    { value: 4, label: "Повторная идентификация" },
]
export const selectPartialIdentificationStatus =[

    { value: 2, label: "Идентификация не пройдена" },
    { value: 3, label: "Идентификация прошла успешно" },
    { value: 4, label: "Повторная идентификация" },

]