import { handlers } from "../helpers/hadlers"

const apiUrl = process.env.REACT_APP_API_URL;

export const tariffService = {
    getLimitByUserId
};

export function getLimitByUserId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "api/user/limits?userUid="+id, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}