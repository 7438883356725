import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { getDateFull, getDateOnly } from "../../../../helpers/date";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import { adminColumns } from '../../../../helpers/tableColumns';
import jwt_decode from "jwt-decode";
import { ROLES } from '../../../../helpers/roleHelper';
import { Input } from 'reactstrap';
import { checkMessageFields } from '../../../helpers/validator';
import { adminService } from '../../../../services/adminService';
import Breadcrumb from '../../../breadcrumb';
import DataTableComponent from '../../../dataTableComponent';
import { useSetState } from '../../../helpers/customHooks';
import PaginationComponent from '../../../pagination';

const SystemMessage = () => {
    const pageSize = 5;
    const [offset, setOffset] = useState(1);
    const [isSuccess, setIsSuccess] = useState(false);
    const [datas, setData] = useState([]);
    const [countElements, setCountElements] = useState(0);
    const userRoleName = jwt_decode(localStorage.getItem("token")).role
    const canEditRoles = [ROLES.ADMIN, ROLES.TOPMANAGER]
    const isReadOnly = !canEditRoles.includes(userRoleName);
    const [isCreate, setIsCreate] = useState(false);
    const types = [{ value: 0, label: "Error" }, { value: 1, label: "Info" }, { value: 2, label: "Warning" }];
    const [errorMesage, setErrorMessage] = useSetState({
        endDate: "",
        title: "",
        message: ""
    })
    const [message, setMessage] = useSetState({
        title: "",
        message: "",
        type: 0,
        endDate: "",
    });
    const [search, setSearch] = useState({
        pageNumber: 1
    });
    let dataTable = [];

    const onLoadData = async () => {
        let data = await adminService.getList(search.pageNumber, pageSize).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);
                setData(data.items);
            }
        });
    }

    useEffect(async () => {
        setIsSuccess(true);
        await onLoadData();
        setIsSuccess(false);

    }, [search])

    const update = async (e) => {
        search.pageNumber = e / pageSize + 1;
        await onLoadData();
    }

    const createMessage = async (e) => {
        if (!checkMessageFields(message, errorMesage)) {
            setIsCreate(!isCreate);
            setIsSuccess(true);
            e.preventDefault();
            await adminService.create(JSON.stringify(message))
                .then(mes => {
                })
                .catch(error => {
                    toast.error(<p>{error.message}</p>);
                });
            toast.info(<p>Уведомление добавлено!</p>)
            setIsSuccess(false);
        }
        await update();
    }

    if (datas.length != 0) {
        datas.forEach(data => {
            const obj = {
                id: isReadOnly ? data.id : <Link to={"/message/edit/" + data.id}>{data.id}</Link>,
                title: data.title,
                message: data.message,
                type: data.type,
                endDate: getDateFull(data.endDate)
            };
            dataTable = [...dataTable, obj];
        })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Администрирование" />
            {!isReadOnly &&
                <div className="card">
                    <div className="card-header">
                        <h5>Добавить новое окно уведомления</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Заголовок</label>
                                    {errorMesage.title != "" ?
                                        (<TextField error helperText={errorMesage.title} variant="outlined" size="small" className="form-control"
                                            onChange={e => setMessage({ title: e.target.value })} defaultValue={message.title} type="text" id="Title" />)
                                        : (<TextField variant="outlined" size="small" className="form-control"
                                            onChange={e => setMessage({ title: e.target.value })} defaultValue={message.title} type="text" id="Title" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Сообщение</label>
                                    {errorMesage.message != "" ?
                                        (<TextField error helperText={errorMesage.message} variant="outlined" size="small" className="form-control"
                                            onChange={e => setMessage({ message: e.target.value })} value={message.message} multiline maxRows={6} id="message" />)
                                        : (<TextField variant="outlined" size="small" className="form-control"
                                            onChange={e => setMessage({ message: e.target.value })} value={message.message} multiline maxRows={6} id="message" />)
                                    }
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Тип сообщения</label>
                                    <Select onChange={e => { setMessage({ type: e.value }) }} defaultValue={{ value: 0, label: "Error" }} placeholder="Тип" options={types}
                                        id="type" />
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <div className="form-group">
                                    <label className="form-label">Дата до</label>
                                    {errorMesage.endDate != "" ? (<Input error
                                        min={new Date().toISOString().slice(0, -8)}

                                        id="datetime-local"
                                        type="datetime-local"
                                        className="form-control"
                                        helperText={errorMesage.endDate}
                                        onChange={e => {
                                            setMessage({ endDate: e.target.value })
                                        }}
                                    />) : (<Input
                                        min={new Date().toISOString().slice(0, -8)}

                                        id="datetime-local"
                                        type="datetime-local"
                                        className="form-control"
                                        onChange={e => {
                                            setMessage({ endDate: e.target.value })
                                        }}
                                    />)}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <button className="btn btn-primary" onClick={e => createMessage(e)} type="submit">Добавить</button>
                        </div>
                    </div>
                </div>
            }
            <div className="container-fluid">
                <div className="row">

                    <DataTableComponent data={dataTable} columns={adminColumns} />

                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }}
                        totalArticles={datas ? countElements : 1000}
                        articlesPerPage={pageSize}
                    /> : <></>
                }
            </div>
        </Fragment>
    );
};

export default SystemMessage;
