export const getRoleName = (roleName) => {
    switch (roleName) {
        case ROLES.ADMIN:
            return "Администратор"
        case ROLES.TOPMANAGER:
            return "Главный менеджер"
        case ROLES.MARKETING:
            return "Специалист по маркетингу"
        case ROLES.SUPPORT:
            return "Помощник"
        case ROLES.DEVELOPER:
            return "Разработчик"
        case ROLES.TESTER:
            return "Тестировщик"
        case ROLES.IDENTIFICATIONAGENT:
            return "Агент идентификации"
        case ROLES.AGENTFMR:
            return "Агент ФМР"
        case ROLES.IDENTIFICATIONADMINISTRATOR:
            return "Администратор идентификации"
    }
}

export const ROLES = {
    ADMIN: "Admin",
    TOPMANAGER: "TopManager",
    MARKETING: "Marketing",
    SUPPORT: "Support",
    DEVELOPER: "Developer",
    TESTER: "Tester",
    IDENTIFICATIONAGENT: "IdentificationAgent",
    AGENTFMR: "AgentFmr",
    IDENTIFICATIONADMINISTRATOR: "IdentificationAdministrator"
}