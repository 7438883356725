import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from "react-router";
import Breadcrumb from '../../breadcrumb';
import { getDocumentsById, userService, getById, getAddressesById } from '../../../services/userService'
import { getDocForms, getDocTypeLabel } from '../../../helpers/docTypeHelper'
import { __DirectiveLocation } from 'graphql';



const CreateDocument = () => {
    const { id } = useParams();
    const { docTypeId } = useParams();
    const [user, setUser] = useState();
    const [oldDoc, setOldDoc] = useState();
    const [oldAddress, setOldAddress] = useState();

    const [docType, setDocType] = useState('');

    useEffect(async () => {
        setDocType(getDocTypeLabel(Number(docTypeId)));
        await userService.getById(id).then(_ => {
            setUser({
                lastName: _.lastName,
                firstName: _.firstName,
                middleName: _.middleName,
                birthDate: _.birthDateTime && dateConvert(_.birthDateTime, "."),
                gender: _.gender,
                birthPlace: _.birthPlace,
            });
        })
        // Временная регистрация
        if (docTypeId === "9") {
            await userService.getAddressesById(id, 1).then(_ => {
                let address = _ && _.addresses && _.addresses[0];
                setOldAddress({
                    apartment: address?.apartment,
                    building: address?.building,
                    city: address?.city,
                    countryCode: address?.countryCode,
                    postCode: address?.postCode,
                    state: address?.state,
                    street: address?.street,
                    frame: address?.frame,
                    addressImages: address?.addressImages
                });
            })
        }
        else {
            await userService.getDocumentsById(id, docTypeId).then(_ => {
                let doc = _ && _.documents && _.documents[0];
                setOldDoc({
                    countryCode: doc?.countryOfResidence,
                    serial: doc?.series,
                    number: doc?.number,
                    issueDate: doc?.issueDate && dateConvert(doc?.issueDate, "."),
                    expiryDate: doc?.expiryDate && dateConvert(doc?.expiryDate, "."),
                    authority: doc?.authority,
                    docImages: doc?.docImages

                });
            })
        }

    }, [])

    const dateConvert = (date, separator) => {
        date = date.split("T")[0];
        const split = date.split(separator);
        if (split.length > 1) {
            return split[2] + "-" + split[1] + "-" + split[0];
        }
        return date;
    }

    return (
        <Fragment>
            <Breadcrumb parent="Документ" title={`${docType}`} />
            {
                getDocForms(Number(docTypeId), id, user, oldDoc, oldAddress)
            }
        </Fragment >
    );
};
export default CreateDocument;