import React from "react";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));

const Country = ({code, size}) => {

    return (
        <div style={{fontSize: size}}>
            <i style={{marginRight: 5}} className={`flag-icon flag-icon-${code}`}/>
            {countries.getName(code, "ru", {select: "official"})}
        </div>
    )
}

export default Country;
