
import { handlers } from "../helpers/hadlers"
const apiUrl = process.env.REACT_APP_API_URL;
export const identificationService = {
    getFmrs,
    getFmrById,
    createFmr,
    deleteFmr,
    updateFmr,
    getAppIdentifications,
    getAppIdentificationById,
    getFreeCouriers,
    updateAppIdentification,
    updateAppIdentificationStatus,
    assignCourierToAppIdentification,
    getDocTypes,
    createDocument,
    createAddress,
    updateIdentificationStatusByUserId,
    addImage,
    getImage,
    deleteImage
};

const httpMethods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE"
}

const identificationName = 'api/identification/';

export function getFmrs() {
    return sendRequestBase(apiUrl + identificationName + `get_fmr_buildings`, httpMethods.get);
}

export function getFmrById(id) {
    return sendRequestBase(apiUrl + identificationName + `get_fmr_building/` + id, httpMethods.get);
}

export function deleteFmr(id) {
    return sendRequestBase(apiUrl + identificationName + `delete_fmr_building/` + id, httpMethods.delete);
}

export function createFmr(message) {
    return sendRequestBaseWithBody(apiUrl + identificationName + `create_fmr_building`, httpMethods.post, message);
}

export function updateFmr(id, message) {
    return sendRequestBaseWithBody(apiUrl + identificationName + `update_fmr_building/` + id, httpMethods.put, message);
}

export function getAppIdentifications(page, name, phoneNumber, status, courierId, isAgent) {
    return sendRequestBase(apiUrl + identificationName + `get_app_identifications/?fullName=${name}&phoneNumber=${phoneNumber}&courierId=${courierId}&status=${status}&isCourier=${isAgent}&filter.pageNumber=${page}&filter.pageSize=20`, httpMethods.get);
}

export function getAppIdentificationById(id) {
    return sendRequestBase(apiUrl + identificationName + `get_app_identification/` + id, httpMethods.get);
}

export function getFreeCouriers() {
    return sendRequestBase(apiUrl + identificationName + `couriers/?status=Free`, httpMethods.get);
}

export function updateAppIdentification(id, message) {
    return sendRequestBaseWithBody(apiUrl + identificationName + `update_app_identification/` + id, httpMethods.put, message);
}

export function updateAppIdentificationStatus(id, message) {
    return sendRequestBaseWithBody(apiUrl + identificationName + `update_app_identification_status/` + id, httpMethods.put, message);
}

export function assignCourierToAppIdentification(message) {
    return sendRequestBaseWithBody(apiUrl + identificationName + `assign_courier`, httpMethods.put, message);
}

function sendRequestBase(url, method) {
    const requestOptions = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(url, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

function sendRequestBaseWithBody(url, method, message) {
    const requestOptions = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(url, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getDocTypes(countryCode, migrantStatus, target) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + identificationName + `get_relevant_documents?countryCode=${countryCode}&migrantStatus=${migrantStatus}&target=${target}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function addImage(message, type) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: message
    };
    return fetch(apiUrl + identificationName + `image/${type}/add_image`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function createDocument(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + identificationName + `document`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function createAddress(message) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + identificationName + `address`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function updateIdentificationStatusByUserId(message) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(apiUrl + identificationName + `update_app_identification_status_by_user_id`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function getImage(imageUrl) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + identificationName + `image/get_images?imageUrl=${imageUrl}`, requestOptions)

        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

export function deleteImage(imageUrl, type) {
    type = type ?? 'document'

    imageUrl = encodeURIComponent(imageUrl);
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + identificationName + `image/${type}/${imageUrl}`, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}