import { Button, CardFooter, Col, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, { Fragment, useEffect, useState, PureComponent } from "react";
import Breadcrumb from '../../breadcrumb'
import { userService } from "../../../services/userService"
import { Link } from "react-router-dom";
import { getDateFull, getDateOnly } from "../../../helpers/date";
import countries from "i18n-iso-countries";
import StatisticChart from "../../staistic-chart";
import DataTableComponent from "../../dataTableComponent";
import PaginationComponent from "../../pagination";
import OperationsTable from "../../operationsTable";
import Select from "react-select";
import { countryService } from '../../../services/countryService';
import Country from "../../country";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { partnerService } from "../../../services/partnerService";
import moment from "moment";
import { userColumns } from "../../../helpers/tableColumns";
import { Loader } from 'react-feather';
import { operationStatuses } from "../../../helpers/statuses";
import { useMediaQuery } from 'react-responsive'

const AboutPartner = () => {
    const options = [];
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const types = ["Пользователи", "Переводы", "Графики"];
    const [active, setActive] = useState(types[0]);
    const [offset, setOffset] = useState(1);
    const [isLoad, setLoad] = useState(true);
    const [datas, setData] = useState([]);
    const [countElements, setCountElements] = useState(0);
    const [isCreate, setIsCreate] = useState(false);
    const [dataChart, setDataChart] = useState([]);

    const [userSearch, setUserSearch] = useState({
        fullName: "",
        phone: "",
        page: 1
    });
    const source = document.location.href.split('/')[4];
    const [operationSearch, setOperationSearch] = useState({
        country: "",
        operationStatus: 0,
        senderId: "",
        sender: "",
        recepient: "",
        search: "",
        startDate: "",
        endDate: "",
        source: "",
        uid: "",
        page: 1,
        source: source
    });

    const onLoadDataCountries = async () => {
        let data = await countryService.getList().then((data) => {
            if (data) {
                options.push({ value: "", label: <p style={{ fontSize: 20, margin: 0, padding: 0 }}>Все</p> })
                data.items.map(country => {
                    options.push({ value: country.country3Code, label: <Country code={country.country2Code.toLowerCase()} size={20} /> })
                });
            }

        });
    }

    const onLoadChart = async () => {
        let data = await partnerService.getChart(source).then((data) => {
            if (data) {
                setDataChart(data);
            }
        });
    }

    const onLoadDataUser = async () => {
        let data = await userService.getListBySource(userSearch.fullName, userSearch.phone, userSearch.page, source).then((data) => {
            if (data) {
                setCountElements(data.pagination.totalRecords);
                setData(data.items);
            }
        });
    }
    useEffect(async () => {
        await onLoadChart();
        await onLoadDataCountries();
        await onLoadDataUser();
        setLoad(false);
    }, [])
    useEffect(async () => {
        await onLoadDataCountries();
    }, [options])

    useEffect(async () => {
        await onLoadDataUser();
    }, [userSearch.fullName, userSearch.phone])

    const update = async (e) => {
        userSearch.page = e / 20 + 1;
        await onLoadDataUser();
    }

    let dataTable = [];
    const handleUserSearch = (event) => {
        if (event.target) {
            setUserSearch({
                ...userSearch,
                [event.target.name]: event.target.value
            })
        }
    };
    const handleOperationSearch = (event) => {
        if (event.target) {
            setOperationSearch({
                ...operationSearch,
                [event.target.name]: event.target.value
            })
        } else {
            if ((typeof event.value) === "number") {
                setOperationSearch({
                    ...operationSearch,
                    ["operationStatus"]: event.value
                })
            } else {
                setOperationSearch({
                    ...operationSearch,
                    ["country"]: event.value
                })
            }
        }
    };

    if (datas.length != 0) {
        datas.forEach(user => {
            const obj = {
                phone: <Link to={"/user/edit/" + user.uid}>{user.phoneNumber}</Link>,
                userRef: <p className="col-12">{user.userRef ? user.userRef.refCode ? <div>⚑</div> : <div>⚐</div> : <div>⚐</div>}</p>,
                registration: getDateFull(user.createdUtcDateTime),
                LFM: <>
                    <p>{user.lastName || "-"} {user.firstName || "-"}<br />
                        {user.middleName || "-"}
                    </p>
                </>,
                birthday: getDateOnly(user.birthDate),
                country: <><i
                    className={`flag-icon flag-icon-${user.address ? user.address.countryCode : "-"} `} /> {user.address ? countries.getName(user.address.countryCode, "ru", { select: "official" }) : "-"}
                </>,
                town: user.address ? user.address.city : "-",
                address: user.address ? ((user.address.street == null ? "-" : user.address.street) + " " +
                    (user.address.building == null ? "-" + " " : user.address.building + ", ") +
                    (user.address.house == null ? "-" : user.address.house)) : "-",
                date_issue: user.document ? getDateOnly(user.document.issueDate) : "-",
                serial_num_pass: user.document ? (user.document.series + " " + user.document.number) : "- -",
                issued_by: user.document ? user.document.authority : "-"
            }
            dataTable = [...dataTable, obj];
        })
    }

    function ToggleGroup() {
        return (
            <div style={{ textAlign: "center" }}>
                <div style={{ marginBottom: "45px" }}>
                    {types.map((type) => (
                        <Button style={{ fontSize: "20px" }} color={active === type ? "primary" : "link"} active={active === type} onClick={() => { setActive(type); ClearSearch(); }}>
                            {type}
                        </Button>
                    ))}
                </div>
                {active == "Пользователи" ? GetUsersTable() : null}
                {active == "Переводы" ? GetOperationsTable() : null}
                {active == "Графики" ? GetShart() : null}
            </div>
        );
    }

    function ClearSearch() {
        setUserSearch({
            fullName: "",
            phone: "",
            page: 1
        });
        setOperationSearch({
            country: "",
            operationStatus: 0,
            senderId: "",
            sender: "",
            recepient: "",
            search: "",
            startDate: "",
            endDate: "",
            source: "",
            uid: "",
            page: 1,
            source: source
        });
    }

    function formatXAxis(tickItem) {
        return moment(tickItem).format('MMM YY')
    }

    function GetShart() {
        if (isLoad) {
            return <Loader></Loader>
        }

        const data = dataChart.map((item) => {
            return (
                {
                    name: item.year + "-" + (item.month / 10 >= 1 ? item.month : "0" + item.month),
                    val: item.countUsers
                }
            );
        });

        return (
            <ResponsiveContainer width={isTabletOrMobile ? "100%" : 1200} height={isTabletOrMobile ? 300 : 600}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" tickFormatter={formatXAxis} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="val"
                        name="Кол-во пользователей"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                    />
                </LineChart>
            </ResponsiveContainer>

        );
    }


    function GetOperationsTable() {
        return (
            <OperationsTable search={operationSearch}>
                <div className="row">
                    {active == "Переводы" ?
                        (<div className="form-group col-xl-3 col-md-6">
                            <input name="uid" placeholder="По Uid перевода" className="form-control" type="text"
                                onChange={event => handleOperationSearch(event)}
                            />
                        </div>) : null
                    }
                    <div className="form-group col-xl-3 col-md-6">
                        <input name="search" placeholder="По номеру перевода Юнистрим и ZamZam" className="form-control" type="text"
                            onChange={event => handleOperationSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6" style={{ textAlign: "left" }}>
                        <Select onChange={handleOperationSearch} placeholder="Поиск по стране" options={options} name="country" />
                    </div>

                    <div className="form-group col-xl-3 col-md-6">
                        <input name="senderId" placeholder="По ФИО и номеру телефона отправителя" className="form-control" type="text"
                            onChange={event => handleOperationSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6">
                        <input name="recepientId" placeholder="По ФИО и номеру телефона получателя" className="form-control" type="text"
                            onChange={event => handleOperationSearch(event)}
                        />
                    </div>
                    <div className="form-group col-xl-3 col-md-6" style={{ textAlign: "left" }}>
                        <Select onChange={handleOperationSearch} placeholder="Поиск по статусу" options={operationStatuses}
                            name="operationStatus" />
                    </div>
                    <div className="form-group col-xl-3 col-md-3">
                        <input name="startDate" type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }}
                            onChange={event => handleOperationSearch(event)}
                            className="form-control"
                            placeholder="Дата от"
                        />

                    </div>
                    <div className="form-group col-xl-3 col-md-3">
                        <input name="endDate" type="text" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus(); }}
                            onChange={event => handleOperationSearch(event)}
                            className="form-control"
                            placeholder="Дата до"
                        />
                    </div>

                </div>

            </OperationsTable>
        );
    }
    function GetUsersTable() {
        if (isLoad) {
            return <Loader></Loader>
        }
        return (
            <div>
                <div className="container-fluid">
                    <div className="col-sm-6">
                        <StatisticChart num={countElements ? countElements : 0}
                            name="Кол-во пользователей" />
                    </div>
                    <div className="row">
                        <div className="form-group col-xl-3 col-md-6">
                            <input name="phone" placeholder="Поиск по телефону" className="form-control" type="text"
                                onChange={event => handleUserSearch(event)}
                            />
                        </div>
                        <div className="form-group col-xl-3 col-md-6">
                            <input name="fullName" placeholder="Поиск по ФИО" className="form-control" type="text"
                                onChange={event => handleUserSearch(event)}
                            />
                        </div>
                    </div>
                    <div className="table-main">
                        <DataTableComponent data={dataTable} columns={userColumns} />
                    </div>
                </div>
                {countElements > 0 ?
                    <PaginationComponent
                        setOffset={(e) => {
                            setOffset(e);
                            update(e);
                        }
                        }
                        totalArticles={datas ? countElements : 6}
                        articlesPerPage={20}
                    /> : <></>
                }
            </div>
        );
    }

    return (
        <Fragment>
            <Breadcrumb parent="Home" title="Партнер" />
            {ToggleGroup()}
        </Fragment>
    );
};

export default AboutPartner;