
import { handlers } from "../helpers/hadlers"
const apiUrl = process.env.REACT_APP_API_URL;
const ssoUrl = process.env.REACT_APP_SSO_URL;

export const partialIdentificationService = {
    getPartialIdentifications,
    getPartialIdentificationById,
    updatePartialIdentificationStatus,
    updateUserPartialIdentificationStatus
};

const httpMethods = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE"
}

const partialIdentificationName = 'api/identification/';


export function getPartialIdentifications(page, name, phoneNumber, status) {
    return sendRequestBase(apiUrl + partialIdentificationName + `get_partial_app_identifications/?fullName=${name}&phoneNumber=${phoneNumber}&status=${status}&filter.pageNumber=${page}&filter.pageSize=20`, httpMethods.get);
}
export function getPartialIdentificationById(id) {
    return sendRequestBase(apiUrl + partialIdentificationName + `get_partial_app_identification/` + id, httpMethods.get);
}
export function updatePartialIdentificationStatus(id, message) {
    return sendRequestBaseWithBody(apiUrl + partialIdentificationName + `update_partial_app_identification/` + id, httpMethods.put, message);
}
export function updateUserPartialIdentificationStatus(message) {
    return sendRequestBaseWithBody(ssoUrl + partialIdentificationName + `partial_identification_status/`, httpMethods.put, message);
}
function sendRequestBase(url, method) {
    const requestOptions = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(url, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}

function sendRequestBaseWithBody(url, method, message) {
    const requestOptions = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: message
    };
    return fetch(url, requestOptions)
        .then(handlers.handleResponse)
        .then(_ => {
            return _;
        });
}
